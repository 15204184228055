import { useParams } from 'react-router-dom';
import { sectionName } from 'components/Dashboard/helpers/controlActiveFilters';
import { changeTypeOfResource } from 'components/Dashboard/helpers/createTypeOfResource';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import useGetSectionFilters from 'components/Dashboard/Filters/useGetSectionFilters.js';
import { useContext } from 'react';
import { store } from 'components/Store';
import ChangeKeywordsCategory from 'components/Dashboard/utilities/changeKeywordsCategory';
import ChangeKeywordsExclusions from 'components/Dashboard/utilities/changeKeywordExclusions';
import { useAuth0 } from '@auth0/auth0-react';
import getUrlParam, { getUrlParamRaw } from 'components/Dashboard/utilities/getUrlParam';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { normalizedDateUK } from 'components/Dashboard/utilities/normalizedDateUK.js';
import dayjs from 'dayjs';

const useGeneralRequestProperties = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, search, referenceState, keywordsLists, sectionsView } = state;
  const { activeReference } = referenceState;
  const { activeSearch } = search;
  const { enableHandpickedContent, enableTwitter, enableReferenceSection } = activePlan;
  const { selectedOptionParliamentLiveView } = sectionsView;

  const topicIdQuery = getUrlParam('topic-id');
  const query = getUrlParam('search') ?? '';
  const list = keywordsLists.find((item) => item.id === (topicIdQuery === 'all' ? null : parseInt(topicIdQuery)));
  const { isAuthenticated } = useAuth0();
  const { generalApiCall } = useGeneralApiCall();

  const section = sectionName();
  const { getSectionFilters } = useGetSectionFilters(section);
  const sectionFilters = getSectionFilters();
  const { searchWithin, sort, filters, unread } = sectionFilters ?? {};

  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);

  const params = useParams();
  const { inboxSection, searchResultsSection } = useMainSectionParameter(params);

  const createRequestProperties = ({ pageNumber, propertiesFilters, directTopic, noUseQuery, customPageSize }) => {
    let url = `/api/${(list !== null && inboxSection) || directTopic ? 'feed' : 'search'}/query`;
    let requestProperties = {
      type: changeTypeOfResource(section),
      filters: propertiesFilters ?? filters,
      [unread ? 'offset' : 'pageNumber']: pageNumber ? pageNumber : unread ? 0 : 1,
    };

    if (section === 'ParliamentaryRecord') {
      requestProperties = {
        ...requestProperties,
        collapseField: 'contentUrl',
        collapseSort: { field: 'contributionOrder', order: 'asc' },
      };
    }

    if (unread && list !== null) {
      requestProperties = { ...requestProperties, limit: pageSize };
    }
    const searchResultsCondition = !enableReferenceSection && section !== 'ElectionKeyUpdates' && searchResultsSection;

    if (!unread) {
      const commonPageSize = searchResultsCondition ? 3 : section === 'ElectionKeyUpdates' ? 100 : pageSize;
      const tweetPageSize = enableTwitter && isAuthenticated && !searchResultsCondition ? pageSize : 2;
      const finalPageSize = section === 'Tweets' ? tweetPageSize : commonPageSize;
      requestProperties = { ...requestProperties, pageSize: customPageSize ?? finalPageSize };
    }

    let sortConditional = !!sort;
    if (sortConditional) {
      requestProperties = { ...requestProperties, sort: sort };
    }

    if (searchWithin) {
      requestProperties = { ...requestProperties, ...searchWithin };
    }

    if (section === 'UpcomingEvents' && !unread) {
      requestProperties = {
        ...requestProperties,
        pageSize: searchResultsCondition ? 3 : 10000,
        pageNumber: 1,
        sort: { field: 'dateTime', order: 'ASC' },
      };
    }

    if ((list !== null && inboxSection) || directTopic) {
      requestProperties = {
        ...requestProperties,
        keywordListId: directTopic?.id ?? list?.id,
        includeExplanations: true,
        unseenContentOnly: unread,
      };
      if (enableHandpickedContent && list?.isHandpicked && section === 'KeyUpdates') {
        requestProperties.handPickedContent = true;
      }
    } else {
      if (activeReference && !noUseQuery) {
        const keywords = getUrlParamRaw('or') ? decodeURIComponent(getUrlParamRaw('or')).split(',') : [];
        const andKeywords = getUrlParamRaw('and') ? decodeURIComponent(getUrlParamRaw('and')).split(',') : [];
        const keywordExclusions = getUrlParamRaw('not') ? decodeURIComponent(getUrlParamRaw('not')).split(',') : [];
        const { topic } = referenceState;
        if (topic) {
          url = `/api/search/query-by-keyword-list`;
          requestProperties = {
            ...requestProperties,
            keywordListId: topic.id,
          };
        } else {
          requestProperties = {
            ...requestProperties,
            query: ChangeKeywordsCategory(keywords),
            andQueries: ChangeKeywordsExclusions(andKeywords),
            mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
          };
        }
      } else {
        requestProperties = {
          ...requestProperties,
          query: getUrlParamRaw('search') ? decodeURIComponent(getUrlParamRaw('search')) : !noUseQuery ? query : '',
        };
      }
    }

    let keyupdatesHighlightConditional =
      section === 'KeyUpdates' &&
      ((activeSearch && list !== null) || (activeSearch && list === null) || activeReference);
    let pathnamesHighlight = ['ParliamentaryRecord', 'LibraryMaterial', 'ParliamentLive'];
    let resourceHighlight = pathnamesHighlight.includes(section) && (activeSearch || activeReference);

    if (keyupdatesHighlightConditional || resourceHighlight) {
      requestProperties.includeHighlights = true;
    }

    if (section === 'Consultations' && sort?.field === 'source') {
      url = '/api/Consultation/departmentswithopenconsultations';
      requestProperties = null;
    }

    if (section === 'ParliamentLive') {
      if (!searchResultsSection) {
        url = `/api/search/query-by-keyword-list`;
      }
      //OP: temporary code
      const dateParam = getUrlParam('startDateTime');
      //
      const newDateFilter = {
        field: 'dateTime',
        //OP: hardcoded value
        value: dayjs
          .utc(normalizedDateUK(dateParam ?? new Date()))
          .startOf('day')
          .format(),
        //
        operator: 'DATE_GTE',
      };
      let newFilters = [...requestProperties.filters];
      newFilters = [
        ...newFilters.filter((item) => !(item?.field === 'dateTime' && item?.operator?.toLowerCase() === 'date_gte')),
        newDateFilter,
        //OP: temporary code
        {
          field: 'dateTime',
          value: normalizedDateUK(dateParam ?? new Date()).format(),
          operator: 'date_lt',
        },
        //
      ];
      if (selectedOptionParliamentLiveView === 'Relevant events') {
        requestProperties = {
          ...requestProperties,
          sort: { field: 'title', order: 'DESC' },
          collapseField: 'parliamentEventId',
          collapseSort: {
            field: 'dateTime',
            order: 'DESC',
          },
          doNotIncludeCollapsedHits: true,
          pageSize: 1000,
        };
      } else {
        requestProperties = {
          ...requestProperties,
          sort: { field: 'dateTime', order: 'DESC' },
        };
      }
      requestProperties = {
        ...requestProperties,
        pageNumber: 1,
        filters: newFilters,
        doNotSearchTitle: true,
      };
    }

    return { url, requestProperties };
  };

  const getLastSeenMarkers = async ({ source }) => {
    const pathname = `/api/feed/feed-last-seen-markers${!!list?.id ? `?keywordListId=${list.id}` : ``}`;
    const method = 'get';
    const results = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      notShowErrorMessage: true,
      requestSource: source,
    });
    if (results) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'lastSeenMarkers', value: results });
    }
  };

  const updateMarkers = async ({ contentResults }) => {
    const { feedItemFlags, pageNumber } = contentResults;
    if (pageNumber === 1) {
      const feedItemsIds = feedItemFlags
        ?.map((item) => item.feedItemId)
        .sort(function (a, b) {
          return a - b;
        });
      const lastItem = feedItemsIds?.[feedItemsIds?.length - 1];
      if (lastItem) {
        const pathname = `/api/feed/set-feed-last-seen-marker`;
        const method = 'post';
        const requestProperties = {
          keywordListId: list?.id,
          contentType: changeTypeOfResource(section),
          feedItemId: lastItem,
        };
        return generalApiCall({
          method,
          pathname,
          requestProperties,
          needsAuthentication: true,
          notShowErrorMessage: true,
        });
      }
    }
  };

  return { createRequestProperties, getLastSeenMarkers, updateMarkers };
};

export default useGeneralRequestProperties;
