import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { net_api_url, store } from 'components/Store.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import Loading from 'components/Login/Loading';
import RegistrationUi from './RegistrationUi';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import validateFields from './validateFields.js';
import { useLocation } from 'react-router-dom';
import ParliamentarianHasTeamPopup from './ParliamentarianHasTeamPopup.js';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall.js';

const RegistrationVerification = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { app_url } = state;
  const [isLoading, setIsLoading] = useState(true);
  const [codeInformation, setCodeInformation] = useState({});
  const location = useLocation();
  const { generalApiCall } = useGeneralApiCall();

  const referralPage = location.pathname.includes('referral');

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const initialFormFields = [
    {
      property: 'email',
      name: 'Email address',
      value: '',
      required: true,
      type: 'text',
      disabled: true,
      validationRule: (val) => val.includes('@'),
      validationMessage: 'Please enter a valid email',
      hidden: true,
    },
    {
      property: 'fullName',
      name: 'Full name',
      value: '',
      required: true,
      type: 'text',
      validationRule: (val) => val.trim().split(' ').length > 1,
      validationMessage: 'Please enter your full name',
    },
    {
      property: 'password',
      name: 'Password',
      value: '',
      type: 'password',
      required: true,
      validationRule: (val) => val.match(/[a-z]/g) && val.match(/[A-Z]/g) && val.match(/[0-9]/g) && val.length >= 8,
      validationMessage:
        'Your password should contain:<ul class="pl-4"><li> 8 characters</li> <li>Lower case letters (a-z)</li> <li>Upper case letters (A-Z)</li><li>Numbers (0-9)</li>',
    },
    {
      property: 'organisation',
      name: 'Which parliamentarian do you work for?',
      value: '',
      type: 'autocomplete',
      required: true,
    },
    {
      property: 'phoneNumber',
      name: 'Phone number',
      value: '',
      type: 'tel',
    },
    {
      property: 'termsAndConditions',
      name: 'Terms and conditions',
      value: false,
      type: 'checkbox',
      validationRule: (val) => val === true,
      validationMessage: 'You must agree to the Terms and Conditions',
    },
  ];
  const initialFormFieldsTostate = useMemo(() => {
    if (referralPage) {
      return initialFormFields.filter((item) => item.property !== 'organisation');
    } else {
      return initialFormFields;
    }
  }, []);

  const [errorMessage, setErrorMessage] = useState('');
  const { loginWithRedirect } = useAuth0();
  const [showPopup, setShowPopup] = useState();
  const [formFields, setFormFields] = useState(initialFormFieldsTostate);
  const loadInitialInformation = useRef();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadInitialInformation.current(source);
    return () => {
      source.cancel('cancelled');
    };
  }, []);

  loadInitialInformation.current = async (source) => {
    const cancelRequestError = () => {
      const customMessage = 'Your registration verification link is invalid or has expired';
      setErrorMessage(customMessage);
      setIsLoading(false);
    };
    const email = getUrlParam('email_address');
    const code = getUrlParam('verification_code');
    if (!email || !code) {
      return cancelRequestError();
    }
    try {
      const pathname = `/api/registration/verify-registration-request?code=${encodeURIComponent(code)}&emailAddress=${encodeURIComponent(email)}`;
      const requestHeaders = { cancelToken: source.token };
      let registrationCode = await axios.get(`${net_api_url}${pathname}`, requestHeaders, '');
      logAnalitys();
      if (!!registrationCode?.data) {
        setErrorMessage('');
        setCodeInformation(registrationCode?.data);
        setFormFields(createFields(registrationCode?.data));
        setIsLoading(false);
      }
    } catch (e) {
      if (e.message !== 'cancelled') cancelRequestError();
    }
  };

  const logAnalitys = () => {
    let title = `Create your profile - PolicyMogul`;
    document.title = title;
    let locationToStorage = {
      title: title,
      storageLocation: { ...location },
    };
    dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
  };

  const createFields = (registrationCode) => {
    const { userType, emailAddress, firstName, lastName } = registrationCode;
    let userFields = formFields.slice();
    //SET THE EMAIL ADDRESS FROM THE API RESULTS
    let emailAddressPosition = userFields.findIndex((item) => item.property === 'email');
    userFields[emailAddressPosition] = {
      ...userFields[emailAddressPosition],
      value: emailAddress,
    };
    if (referralPage) {
      //PREPOPULATE FULLNAME
      let fullName = `${firstName} ${lastName}`;
      let fullNamePosition = userFields.findIndex((item) => item.property === 'fullName');
      userFields[fullNamePosition] = {
        ...userFields[fullNamePosition],
        value: fullName,
      };
    }
    if (userType === 'Parliamentarian') {
      let fullNamePosition = userFields.findIndex((item) => item.property === 'fullName');
      userFields[fullNamePosition] = {
        ...userFields[fullNamePosition],
        type: 'autocomplete',
      };
      let parliamentarianWorkForPosition = userFields.findIndex((item) => item.property === 'organisation');
      userFields.splice(parliamentarianWorkForPosition, 1);
    }
    return userFields;
  };

  const modifyValue = (property, value) => {
    let updatedFormFileds = formFields.slice();
    let propertyIndex = updatedFormFileds.findIndex((item) => item.property === property);
    let newPropertyObject = {
      ...updatedFormFileds[propertyIndex],
      errorMessage: null,
      value: value,
    };
    updatedFormFileds[propertyIndex] = newPropertyObject;
    setFormFields(updatedFormFileds);
  };

  const registerUser = async (e) => {
    let validation = validateFields({ formFields, setFormFields });
    if (validation) {
      if (!referralPage) {
        let checkParliamentarianTeam = await callParliamentarinTeamExists();
        if (checkParliamentarianTeam) {
          setShowPopup(true);
        } else {
          registerUserFunction(e);
        }
      } else {
        registerUserFunction(e);
      }
    }
  };

  const getParliamentarianId = () => {
    const { userType } = codeInformation;
    let field = 'organisation';
    if (userType === 'Parliamentarian') {
      field = 'fullName';
    }
    return field;
  };

  const callParliamentarinTeamExists = async (e) => {
    let fieldParliamentarian = getParliamentarianId();
    let finalFields = formFields.slice();
    let fieldItem = finalFields.find((item) => item.property === fieldParliamentarian);
    let pathname = `/api/registration/check-if-parliamentarian-team-exists?parliamentarianId=${fieldItem.value.id}`;
    let method = 'get';
    let results = await generalApiCall({ pathname, method });
    return results;
  };

  const getReferralParams = () => {
    const newParams = {
      utm_source: getUrlParam('utm_source'),
      utm_medium: getUrlParam('utm_medium'),
      utm_campaign: getUrlParam('utm_campaign'),
    };
    let params = new URLSearchParams(newParams);
    return encodeURIComponent(params);
  };

  const registerUserFunction = async (e) => {
    const { code, userType, emailAddress } = codeInformation;
    const finalFields = formFields.slice();
    let fieldParliamentarian = getParliamentarianId();
    let fieldItem = finalFields.find((item) => item.property === fieldParliamentarian);
    let route = referralPage ? 'register-verified-user' : 'register-verified-parlimentarian-or-staff';
    const pathname = `/api/registration/${route}?code=${code}${referralPage ? `&registrationSource=${getReferralParams()}` : `&parliamentarianId=${fieldItem.value.id}`}`;
    const method = 'post';
    const requestProperties = referralPage ? {} : { userType: userType };
    let termsAndConditionsPosition = formFields.findIndex((item) => item.property === 'termsAndConditions');
    finalFields.splice(termsAndConditionsPosition, 1);
    finalFields.forEach((item) => {
      requestProperties[item.property] = item.property !== fieldParliamentarian ? item.value : `${item.value.label}`;
    });
    let fullNameItem = finalFields.find((item) => item.property === 'fullName');
    let fullNameParts =
      fullNameItem.type === 'autocomplete' ? fullNameItem.value.label.split(' ') : fullNameItem.value.split(' ');
    requestProperties.firstName = fullNameParts[0];
    requestProperties.lastName = fullNameParts.splice(1).join(' ');
    let button = e.target;
    button.disabled = true;
    let results = await generalApiCall({ pathname, method, requestSource: source, requestProperties });

    let passwordItem = finalFields.find((item) => item.property === 'password');
    if (!!results) {
      loginWithRedirect({
        authorizationParams: {
          login_hint: emailAddress,
          userPassword: encodeURIComponent(passwordItem.value),
          redirect_uri: `${app_url}/${window.location.search}/login-success`,
        },
      });
    }
    button.disabled = false;
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <RegistrationUi
            errorMessage={errorMessage}
            formFields={formFields}
            modifyValue={modifyValue}
            registerUser={registerUser}
            codeInformation={codeInformation}
          />
          <ParliamentarianHasTeamPopup
            setShowPopup={setShowPopup}
            showPopUp={showPopup}
            registerUserFunction={registerUserFunction}
          />
        </>
      )}
    </>
  );
};

export default RegistrationVerification;
