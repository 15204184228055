import React, { useContext } from 'react';
import { store } from '../../Store.js';
import useRememberPosition from '../hooks/useRememberPosition.js';
import TagsLists from '../TagsList/index.js';
import createMarkup from '../helpers/createMarkup.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const ParliamentLiveItem = (props) => {
  const { isAll, item, index, includeTags, scrollBarRef, itemOnclick, array } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, contentResults, lastSeenMarkers, itemsViewed, sectionsView } = state;
  const { activeSearch, list } = search;
  const { contentUrl, title, subject, highlights, body, dateTime } = item ?? {};
  const { selectedOptionParliamentLiveView } = sectionsView;
  const ParliamentLiveSearch = contentResults['searchResults'].ParliamentLive;
  const explanations =
    props.explanations ?? activeSearch
      ? ParliamentLiveSearch !== undefined
        ? ParliamentLiveSearch.explanations
        : []
      : [];
  const feedItemFlags =
    props.feedItemFlags ?? activeSearch
      ? ParliamentLiveSearch !== undefined
        ? ParliamentLiveSearch.feedItemFlags
        : []
      : [];
  const explanationsToRender = explanations ?? ParliamentLiveSearch?.explanations;
  const { rememberPosition } = useRememberPosition();
  const collapsedHitItem = ParliamentLiveSearch?.collapsedHits?.[index];
  const feedItem = feedItemFlags?.[index];
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  const unseenConditional =
    list !== null &&
    !isAll &&
    includeTags &&
    feedItemFlags?.length > 0 &&
    !!lastSeenMarkers['parliamentLiveFeedItemId'] &&
    lastSeenMarkers['parliamentLiveFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition;

  const relevantEventsCondition = selectedOptionParliamentLiveView === 'Relevant events';
  const timeAgo = (date) => {
    const now = dayjs().tz('Europe/London');
    const givenDate = dayjs(date).tz('Europe/London');
    const diffInSeconds = now.diff(givenDate, 'second');

    if (diffInSeconds < 0) {
      const absDiffInSeconds = Math.abs(diffInSeconds);
      if (absDiffInSeconds < 60) {
        return `Will start in ${absDiffInSeconds} seconds`;
      }
      const absDiffInMinutes = Math.abs(now.diff(givenDate, 'minute'));
      if (absDiffInMinutes < 60) {
        return `Will start in ${absDiffInMinutes} minute${absDiffInMinutes > 1 ? 's' : ''}`;
      }
      const absDiffInHours = Math.abs(now.diff(givenDate, 'hour'));
      return `Will start in ${absDiffInHours} hour${absDiffInHours > 1 ? 's' : ''}`;
    }

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    }

    const diffInMinutes = now.diff(givenDate, 'minute');
    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
    }

    const diffInHours = now.diff(givenDate, 'hour');
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  };

  return (
    <>
      <a
        href={`${contentUrl}`}
        target='_blank'
        rel='noopener noreferrer'
        className={`content-item-link`}
        style={{ zIndex: array ? array.length - index : 1 }}
        onClick={(e) => {
          if (scrollBarRef) {
            rememberPosition('ParliamentLive', scrollBarRef, index);
          }
          if (itemOnclick) {
            itemOnclick(e);
          }
        }}
      >
        <li className={`my-2 content-item ${relevantEventsCondition ? '' : 'relevant-remark-item'}`}>
          {unseenConditional && (
            <>
              <span className='unseen-dot' />
            </>
          )}
          <div className='p-3 p-sm-4'>
            {relevantEventsCondition && title && <h4 className={`mb-0 flex-grow-1 content-item-title`}>{title}</h4>}
            <div className='row mx-0 align-items-start'>
              <div className='content-title parliament-live-title w-100 px-0'>
                <p className={`title-body mb-0 ${relevantEventsCondition ? 'mt-3' : ''}`}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(highlights ? highlights.highlightedBodySnippets[0] : body)}
                  />
                </p>
                <p className={`heading-date mb-0 pt-2 d-flex align-items-center`}>
                  {relevantEventsCondition ? (
                    <>
                      <span>{dayjs(dateTime).format('dddd D MMMM YYYY')}</span>
                      <span className='dot mt-0'></span>
                    </>
                  ) : (
                    <>
                      <span
                        className={`${subject ? `parliament-live-item-subject` : `legislation-${title?.replaceAll('House of ', '')?.toLowerCase()}`}`}
                      >
                        {title ?? subject}
                      </span>
                      <span className='dot mt-0'></span>
                    </>
                  )}
                  <span className='date'>
                    Started at {dayjs(dateTime).format('HH:mm').replaceAll(' ', '').toLowerCase()}
                  </span>
                </p>
                <TagsLists
                  feedItemFlags={feedItemFlags}
                  explanations={explanationsToRender}
                  index={index}
                  title={'Parliament Live'}
                  live={true}
                  liveText={timeAgo(dateTime)}
                />
                {collapsedHitItem?.totalHits && relevantEventsCondition && (
                  <p className='mt-2 mb-0 text-light-blue paragraph-p3 d-inline-block'>
                    {collapsedHitItem?.totalHits} relevant contribution{collapsedHitItem?.totalHits === 1 ? '' : 's'} so
                    far
                  </p>
                )}
              </div>
            </div>
          </div>
        </li>
      </a>
    </>
  );
};

export default ParliamentLiveItem;
