import React, { useContext, useCallback, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import idsWithoutImages from 'components/Dashboard/KeyUpdates/idsWithoutImages';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { Link, useLocation } from 'react-router-dom';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { useCollapse } from 'react-collapsed';
import { useState } from 'react';
import { SaveButton } from 'components/Dashboard/components/Share';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { Notes, Emails, Groups } from './ActivityLog';
import StakeholderTagsPopup, { StakeholderTagsAddContactPopup } from './StakeholderTagsPopup';
import useAddTagsToStakeholder from 'components/Dashboard/StakeHolders/StakeholderPage/useAddTagsToStakeholder';
import parliamentarianUsers from 'components/Dashboard/SidebarElements/parliamentarianUsers';
import useValidateContactsOversubscribed from 'components/Dashboard/utilities/useValidateContactsOversubscribed';
import { store } from 'components/Store';
import {
  ContactHolMemberInfoIcon,
  contactHolMemberCondition,
} from 'components/Dashboard/Influence/CrmContacts/CrmContactsTable/CrmContactItem/CrmContactItem';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import ChampionStatus from 'components/Dashboard/Influence/tableComponents/ChampionStatus';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';

const BioElements = React.forwardRef((props, ref) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const {
    StakeholderInformation,
    enableStakeholders,
    activePlan,
    sendEmailToContact,
    setActiveTab,
    activeTab,
    setShowContactsPopup,
    setShowStakeholderCrmPopup,
    bioElementsScrollBarPosition,
    updateChampionStatus,
  } = props;
  const {
    party,
    constituency,
    email,
    website,
    positions,
    selectCommittees,
    lordsSelectCommittees,
    allPartyGroups,
    profile,
    contactInformation,
    categories,
  } = StakeholderInformation;

  const [selectedVisibility, setSelectedVisibility] = useState('user');
  const [stakeholderEmails, setStakeholderEmails] = useState([]);
  const location = useLocation();
  const { isAuthenticated, user } = useAuth0();
  const isMobile = useIsMobile();
  const { code } = activePlan;

  const basicInfoCondition = constituency !== null || party !== null || email !== null || website !== null;
  const isAddedToMyContacts = contactInformation?.length > 0;

  const { newAgencyPlan } = useTopicOrClientWord();

  const sectionCommitteesCondition = categories?.includes('MP') || categories?.includes('Lord');
  return (
    <>
      <div ref={ref}>
        <div className={`box-top-rounded biography-container`}>
          {/* PROFILE IMAGE AND PARTY */}
          {!isMobile && (
            <div className='sticky-item box-top-rounded border-bottom-none'>
              <ProfileImage
                StakeholderInformation={StakeholderInformation}
                setShowContactsPopup={setShowContactsPopup}
                setShowStakeholderCrmPopup={setShowStakeholderCrmPopup}
                activePlan={activePlan}
              />
            </div>
          )}

          {/* INFORMATION BOX */}
          <div className='biography-secondary-container border-right border-left'>
            <BioElementsTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setShowContactsPopup={setShowContactsPopup}
              setShowStakeholderCrmPopup={setShowStakeholderCrmPopup}
              newAgencyPlan={newAgencyPlan}
              selectedVisibility={selectedVisibility}
              setSelectedVisibility={setSelectedVisibility}
              user={user}
              stakeholderEmails={stakeholderEmails}
            />
            {activeTab === 'Profile' && (
              <>
                <div
                  className={`${basicInfoCondition ? 'px-4 border-bottom pb-3' : 'basic-information-empty-adjustment'} ${parliamentarianUsers(code) ? 'pt-4' : ''}`}
                >
                  {constituency !== null && (
                    <>
                      <h4 className='category-subtitle member-info-subtitle'>Constituency</h4>
                      <p className='mb-0 member-data'>{constituency}</p>
                    </>
                  )}
                  {party !== null && (
                    <>
                      <h4 className='category-subtitle member-info-subtitle'>Political party</h4>
                      <p className='mb-0 member-data'>{party}</p>
                    </>
                  )}
                  {email !== null && (
                    <>
                      <h4 className='category-subtitle member-info-subtitle'>Email</h4>
                      {isAuthenticated && (
                        <>
                          {(parliamentarianUsers(code) || enableStakeholders) && (
                            <>
                              <p className='mb-0 member-data'>
                                <a
                                  className='external-url mail-url'
                                  href={`mailto:${email}`}
                                  id={'stakeholder-email-external-link'}
                                >
                                  {email}
                                </a>
                                {contactHolMemberCondition(email) && (
                                  <ContactHolMemberInfoIcon
                                    className='paragraph-p3 ml-2 d-inline-block'
                                    style={{ color: 'rgba(0, 18, 43, 0.55)', transform: 'translateY(1px)' }}
                                  />
                                )}
                                <button
                                  className='general-button member-email-button'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    let tempInput = document.createElement('input');
                                    tempInput.style = 'position: absolute; left: -1000px; top: -1000px;';
                                    tempInput.value = email;
                                    document.body.appendChild(tempInput);
                                    tempInput.select();
                                    document.execCommand('copy');
                                    document.body.removeChild(tempInput);
                                    createNotification('success', `Email address has been copied`);
                                  }}
                                  data-tip='Copy email address'
                                  data-for='general-tooltip'
                                >
                                  Copy
                                </button>
                              </p>
                            </>
                          )}
                          {!enableStakeholders && (
                            <Link className='simple-link-button' to={`/pricing?returnUrl=${location.pathname}`}>
                              Upgrade to Mogul to reveal contact info
                            </Link>
                          )}
                        </>
                      )}
                      {!isAuthenticated && (
                        <button
                          className='general-button simple-link-button'
                          onClick={() => {
                            window.location.assign(`/register?reg_source=stakeholder_page_see_email`);
                          }}
                        >
                          Sign up to reveal contact info
                        </button>
                      )}
                    </>
                  )}
                  {website !== null && (
                    <>
                      <h4 className='category-subtitle member-info-subtitle'>Personal website</h4>
                      <p className='mb-0 member-data'>
                        <a className='external-url' href={website} rel='noopener noreferrer' target='_blank'>
                          {website}
                          <button
                            className='general-button member-email-button external-url-button'
                            data-tooltip-content='Open website'
                            data-tooltip-id='general-tooltip'
                          >
                            Open in a new tab
                          </button>
                        </a>
                      </p>
                    </>
                  )}
                </div>

                {isAuthenticated && !parliamentarianUsers(code) && !!contactInformation?.[0] && (
                  <div className='px-4 border-bottom py-4'>
                    <h4 className='category-subtitle member-info-subtitle'>
                      Champion status {newAgencyPlan ? `for ${team?.teamName}` : ''}
                    </h4>
                    <div className='pt-1 col-lg-8 px-0'>
                      <ChampionStatus
                        updateChampionStatus={updateChampionStatus}
                        scrollBarPosition={bioElementsScrollBarPosition}
                        contact={contactInformation?.[0]}
                      />
                    </div>
                  </div>
                )}
                {!parliamentarianUsers(code) && (
                  <TagsSection
                    newAgencyPlan={newAgencyPlan}
                    contactInformation={contactInformation}
                    setShowContactsPopup={setShowContactsPopup}
                    StakeholderInformation={StakeholderInformation}
                    isAuthenticated={isAuthenticated}
                    isAddedToMyContacts={isAddedToMyContacts}
                  />
                )}
                <CreateSectionsCommittees field={positions} title='Positions' className='position-subtitle' />
                <BiographyElement
                  profile={profile}
                  isAuthenticated={isAuthenticated}
                  setShowContactsPopup={setShowContactsPopup}
                />
                {sectionCommitteesCondition && (
                  <>
                    <CreateSectionsCommittees field={selectCommittees} title='Select Committees' />
                    <CreateSectionsCommittees field={lordsSelectCommittees} title='Lords Select Committees' />
                    <CreateSectionsCommittees
                      field={allPartyGroups?.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                      })}
                      title='APPGs'
                    />
                  </>
                )}

                {!isAuthenticated && (
                  <div className={`px-4 pt-3 border-bottom`}>
                    <h4 className={`category-subtitle member-info-subtitle`}>Have your voice heard</h4>
                    <p className={`biography-element mb-2`}>
                      Hundreds of MPs, peers and advisers use PolicyMogul to find lobbying material added by campaigners
                      and organisations
                    </p>
                    <p className='pb-4 mb-0'>
                      <Link
                        to='/register?reg_source=stakeholder_page_add-lobbying-material'
                        className='simple-link-button paragraph-p2'
                      >
                        Add your lobbying material to PolicyMogul
                      </Link>
                    </p>
                  </div>
                )}
              </>
            )}
            {activeTab === 'Notes' && (
              <Notes
                StakeholderInformation={StakeholderInformation}
                activePlan={activePlan}
                selectedVisibility={selectedVisibility}
                setSelectedVisibility={setSelectedVisibility}
              />
            )}
            {activeTab === 'Emails' && (
              <Emails
                StakeholderInformation={StakeholderInformation}
                activePlan={activePlan}
                sendEmailToContact={sendEmailToContact}
                setStakeholderEmails={setStakeholderEmails}
              />
            )}
            {activeTab === 'Groups' && (
              <Groups StakeholderInformation={StakeholderInformation} activePlan={activePlan} />
            )}
          </div>
        </div>
      </div>
    </>
  );
});

const TagsSection = (props) => {
  const {
    newAgencyPlan,
    contactInformation,
    tags: tagsProp,
    StakeholderInformation,
    isAuthenticated,
    isAddedToMyContacts,
    customContact,
  } = props ?? {};
  const tags = tagsProp ? tagsProp : contactInformation?.[0]?.tags;
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showAddContactsPopup, setShowAddContactsPopup] = useState(false);

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { team, activePlan } = state;
  const { enableCrm } = activePlan;
  const { addTagsToStakeholder } = useAddTagsToStakeholder({ customContact });
  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  let setShowStakeholderCrmPopup = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showStakeholderCrmPopup',
      value: val,
    });
  };
  let setShowContactsPopup = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showContactsPopup',
      value: { show: val },
    });
  };

  return (
    <>
      <div className='px-4 py-3 border-bottom'>
        <h4 className='category-subtitle member-info-subtitle'>Tags {newAgencyPlan ? `for ${team?.teamName}` : ''}</h4>
        <p className='mb-0 member-data mt-2 pt-1'>
          {tags?.map((item, index) => {
            return (
              <span
                className='d-inline-block mr-2 border rounded pl-2 pr-4 mb-2 py-1 position-relative'
                key={`item-tag-${index}`}
              >
                {item}
                <button
                  className='general-button react-tagsinput-remove'
                  onClick={async (e) => {
                    if (enableCrm) {
                      let el = e.target;
                      el.parentElement.classList.add('tag-button-disabled');
                      el.disabled = true;
                      let newTags = [...tags];
                      newTags.splice(index, 1);
                      await validateContactsOversubscribed(async () => {
                        await addTagsToStakeholder(null, newTags);
                      }, 'tag');
                      el.disabled = false;
                      el.parentElement.classList.remove('tag-button-disabled');
                    } else {
                      setShowStakeholderCrmPopup(true);
                    }
                  }}
                />
              </span>
            );
          })}
          <button
            className='general-button simple-link-button'
            onClick={() => {
              if (isAuthenticated) {
                if (enableCrm) {
                  if (isAddedToMyContacts) {
                    validateContactsOversubscribed(() => setShowTagsModal(true), 'tag');
                  } else {
                    setShowAddContactsPopup(true);
                  }
                } else {
                  setShowStakeholderCrmPopup(true);
                }
              } else {
                setShowContactsPopup(true);
              }
            }}
          >
            + Add new tag
          </button>
        </p>
      </div>
      <StakeholderTagsPopup
        setShowPopup={setShowTagsModal}
        showPopUp={showTagsModal}
        StakeholderInformation={StakeholderInformation ?? contactInformation}
        customContact={customContact}
      />
      <StakeholderTagsAddContactPopup
        setShowPopup={setShowAddContactsPopup}
        showPopUp={showAddContactsPopup}
        StakeholderInformation={StakeholderInformation}
      />
    </>
  );
};

const BioElementsTabs = (props) => {
  const {
    setActiveTab,
    activeTab,
    setShowContactsPopup,
    setShowStakeholderCrmPopup,
    newAgencyPlan,
    selectedVisibility,
    setSelectedVisibility,
    user,
    stakeholderEmails,
  } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { code, enableCrm } = activePlan;

  const { isAuthenticated } = useAuth0();
  const crmTabs = [
    {
      name: isAuthenticated ? 'Notes' : 'Write a note',
      tab: 'Notes',
      icon: () => {
        return (
          <span
            className='icon-briefing-material d-none d-xl-inline-block paragraph-p2'
            style={{ marginRight: '0.65em', verticalAlign: 'middle' }}
          />
        );
      },
    },
    {
      name: 'Groups',
      tab: 'Groups',
      icon: () => {
        return (
          <span
            className='icon-team d-none d-xl-inline-block paragraph-p2'
            style={{ marginRight: '0.75em', verticalAlign: 'middle', marginTop: '1px' }}
          />
        );
      },
      notShow: !isAuthenticated,
    },
    {
      name: isAuthenticated ? 'Emails' : 'Send an email',
      tab: 'Emails',
      icon: () => {
        return (
          <span
            className='icon-email d-none d-xl-inline-block paragraph-p4'
            style={{ marginRight: '0.75em', verticalAlign: 'middle', marginTop: '1px' }}
          />
        );
      },
    },
  ];
  return (
    <>
      {!parliamentarianUsers(code) && (
        <div className={`flex-centered justify-content-between p-4`}>
          {/*${isAuthenticated ? 'main-sticky bg-main-white mb-3 authenticated-stakeholder-tabs' : ' */}
          <div className='flex-centered stakeholder-tabs-container flex-grow-1'>
            <button
              className={`general-button biography-controllers-button border rounded  ${activeTab === 'Profile' ? 'third-button text-white' : 'fourth-button'}`}
              onClick={() => {
                setActiveTab('Profile');
              }}
            >
              <span className='icon-profile d-none d-xl-inline-block paragraph-p3' style={{ marginRight: '0.75em' }} />{' '}
              About
            </button>
            {crmTabs
              .filter((item) => !item.notShow)
              ?.map((item) => {
                const { icon, tab, name } = item;
                return (
                  <button
                    key={`stakeholder-tab-${tab}`}
                    className={`general-button biography-controllers-button border rounded ml-3 ${activeTab === tab ? 'third-button text-white' : 'fourth-button'}`}
                    onClick={() => {
                      if (isAuthenticated) {
                        if (enableCrm) {
                          setActiveTab(tab);
                        } else {
                          setShowStakeholderCrmPopup(true);
                        }
                      } else {
                        setShowContactsPopup(true);
                      }
                    }}
                  >
                    {icon()}
                    <span>{name}</span>
                  </button>
                );
              })}
          </div>
          {activeTab === 'Emails' && newAgencyPlan && (
            <AgencyLogoAndExplanation
              activeTab={activeTab}
              selectedVisibility={selectedVisibility}
              setSelectedVisibility={setSelectedVisibility}
              user={user}
              stakeholderEmails={stakeholderEmails}
            />
          )}
        </div>
      )}
    </>
  );
};

const ProfileImage = (props) => {
  const { StakeholderInformation } = props;
  const { categories, imageId, imageUrl, party, officalName, summary } = StakeholderInformation;

  const globalState = useContext(store);
  const { dispatch } = globalState;

  const createMainBarHouse = () => {
    if (categories?.includes('MP')) {
      return 'bar-commons';
    } else if (categories?.includes('Lord')) {
      return 'bar-lords';
    } else if (categories?.includes('Special Adviser') || categories?.includes('Special Advisor')) {
      return 'bar-special-adviser';
    } else {
      return 'bar-special-adviser';
    }
  };

  const partyClass = () => `member-${party !== null ? normalize(party.toLowerCase()) : ''}-picture`;
  const isMobile = useIsMobile();

  return (
    <div
      className={`d-flex align-items-center profile-image-content py-3 px-4 profile-image-bar ${createMainBarHouse()} profile-image-${partyClass()}`}
    >
      <img
        className={`${partyClass()} profile-main-image`}
        alt='stakeholder profile'
        src={
          imageUrl ??
          (imageId !== 0 && imageId !== null && !idsWithoutImages.includes(imageId)
            ? `https://www.parliamenttoday.com/image.php?imageid=${imageId}&ml=1`
            : `${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png`)
        }
      />
      <div className='position-relative pt-2 pl-3 flex-grow-1' style={{ zIndex: 100 }}>
        <div className='profile-main-subtext'>
          <div className='d-flex align-items-center align-items-lg-start justify-content-between'>
            <div className='flex-grow-1 '>
              <h3 className='main-title mb-1'>{officalName}</h3>
              {!isMobile && <p className='mb-0 hidden-lines hidden-two-lines'>{summary}</p>}
            </div>
            <div className='profile-share-buttons pl-2 flex-centered'>
              <SaveButton
                item={{
                  id: StakeholderInformation.id,
                  objectID: StakeholderInformation.objectId,
                  type: StakeholderInformation.objectType,
                  party,
                  title: officalName,
                  imageId,
                }}
              />
              <button
                className='general-button circle-btn-with-hover bg-white ml-2'
                data-tooltip-content={'Share profile'}
                data-tooltip-id={'general-tooltip'}
                onClick={(e) => {
                  e.target.blur();
                  dispatch({
                    type: 'MODIFY_SECTION',
                    parameter: 'shareStakeholderPopupProps',
                    value: {
                      title: StakeholderInformation.officalName,
                      header: 'Share this profile',
                      show: true,
                    },
                  });
                }}
              >
                <span
                  className='icon-share d-block paragraph-p3'
                  style={{
                    transform: 'rotate(350deg) scale(1.05)',
                  }}
                ></span>
              </button>
            </div>
          </div>
        </div>
        {isMobile && <p className='mb-0 mt-2 hidden-lines hidden-two-lines'>{summary}</p>}
      </div>
    </div>
  );
};

const CreateSectionsCommittees = (props) => {
  const { field, title, className } = props;
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <>
      {field !== null && field !== undefined && field && field.length > 0 && (
        <div className={`border-bottom ${isExpanded ? 'pb-3' : ''}`}>
          <div
            className={`${isExpanded ? 'pt-3' : 'py-3'} pointer px-4 toggle-no-button`}
            {...getToggleProps({
              onClick: () => setExpanded((prevExpanded) => !prevExpanded),
            })}
          >
            <h4 className={`feature-subtitle category-subtitle dropdown-item-element ${isExpanded ? 'open' : ''}`}>
              {title}
            </h4>
          </div>
          <div {...getCollapseProps()} className='px-4'>
            <div className='pt-1'>
              {field.map((item, index) => (
                <div className='my-2 member-data' key={`comittee-${index}`}>
                  <p className={`mb-0 committe-subtitle ${className ? className : ''}`}>{item}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const BiographyElement = (props) => {
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <div className={`border-bottom ${isExpanded ? 'pb-3' : ''}`}>
      <div
        className={`${isExpanded ? 'pt-3' : 'py-3'} pointer px-4 toggle-no-button`}
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        })}
      >
        <h4 className={`category-subtitle member-info-subtitle dropdown-item-element ${isExpanded ? 'open' : ''}`}>
          Biography
        </h4>
      </div>
      <div {...getCollapseProps()} className='px-4'>
        <BiographyContent {...props} />
      </div>
    </div>
  );
};

const BiographyContent = (props) => {
  const { profile, isAuthenticated, setShowContactsPopup } = props;
  const [activeEllipsis, setActiveEllipsis] = useState(false);
  const location = useLocation();
  const createMarkup = (body) => {
    return { __html: body };
  };
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const isEllipsisActive = (e) => {
    if (e) {
      return e.offsetHeight < e.scrollHeight;
    }
  };

  const bioElement = useCallback((node) => {
    if (node !== null) {
      setActiveEllipsis(isEllipsisActive(node));
    }
  }, []);
  return (
    <>
      <div className='pt-1'>
        <div
          dangerouslySetInnerHTML={createMarkup(profile)}
          className={`biography-element hidden-lines biography-element-hidden ${isExpanded ? 'd-none' : ''}`}
          ref={bioElement}
        />
      </div>

      <div {...getCollapseProps()}>
        <div
          className={`biography-element ${isExpanded ? 'd-block' : 'd-none'}`}
          dangerouslySetInnerHTML={createMarkup(profile)}
        ></div>
      </div>
      <p
        className='mb-2 text-light-blue paragraph-p2 pointer toggle-no-button'
        style={{ visibility: activeEllipsis || isExpanded ? 'visible' : 'hidden' }}
        {...getToggleProps({
          onClick: () => {
            if (isAuthenticated) {
              setExpanded((prevExpanded) => !prevExpanded);
            } else {
              setShowContactsPopup({
                show: true,
                url: `/register?reg_source=stakeholder-see-more&returnUrl=${location.pathname}`,
              });
            }
          },
        })}
      >
        See {isExpanded ? 'less' : 'more'}
      </p>
    </>
  );
};

const AgencyLogoAndExplanation = ({
  activeTab,
  selectedVisibility,
  setSelectedVisibility,
  user,
  stakeholderEmails,
}) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, activePlan } = state;
  return activeTab === 'Notes' ? (
    <NoteVisibilitySelect
      user={user}
      team={team}
      selectedVisibility={selectedVisibility}
      setSelectedVisibility={setSelectedVisibility}
      activePlan={activePlan}
    />
  ) : (
    <>
      {stakeholderEmails?.length > 0 && (
        <img
          style={{ width: '30px', height: '30px' }}
          alt='avatar'
          data-tooltip-place={'top'}
          data-tooltip-content={
            'Emails logged for this stakeholder are specific to this client. To send emails for a different client, switch using the top navigation'
          }
          data-tooltip-id='general-tooltip'
          data-tooltip-class-name={'general-tooltip pricing-feature-tooltip report-popup-tooltip'}
          className='rounded-circle img-fluid'
          src={team?.organisationLogo ?? `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`}
        />
      )}
    </>
  );
};

const NoteVisibilitySelect = (props) => {
  const { user, team, selectedVisibility, setSelectedVisibility, disabled, activePlan } = props;
  const isMobile = useIsMobile();
  const [showActions, setShowActions] = useState(false);
  const actionsElement = useRef();
  const hideActions = () => {
    setShowActions(false);
  };
  useOutsideElement(actionsElement, hideActions);
  const { width, top, left, height } = actionsElement?.current?.getBoundingClientRect() ?? {};
  return (
    <div className='position-relative note-visibility-select' ref={actionsElement}>
      <button
        className={`general-button rounded-button ${disabled ? 'default-cursor' : 'd-flex'}`}
        onClick={() => {
          if (!disabled) {
            setShowActions(!showActions);
          }
        }}
      >
        {selectedVisibility === 'user' ? (
          <AgencyImage team={team} />
        ) : (
          <OrganizationImage activePlan={activePlan} user={user} />
        )}
        {!disabled && (
          <span
            className={`dropdown-item-element dropdown-item-element-10 pr-3 ${showActions ? 'open' : ''}`}
            style={{ marginTop: '3px' }}
          ></span>
        )}
      </button>
      {showActions && (
        <div
          className='position-fixed rounded border bg-main-white box-outstanding adjustment-of-position py-2'
          style={{
            width: '320px',
            top: `${top + height + 5}px`,
            left: `${left + width - (isMobile ? 320 : 117)}px`,
          }}
        >
          <div
            className='d-flex px-4 py-3 pointer action-state-hover'
            onClick={(e) => {
              setSelectedVisibility('user');
              hideActions();
            }}
          >
            <div className='pt-1 analytics-share-icon-container' style={{ minWidth: '30px', marginTop: '-4px' }}>
              <AgencyImage team={team} style={{ width: '30px', height: '30px' }} />
            </div>
            <div className='pl-3'>
              <p className='font-weight-bold mb-1'>Client-specific note</p>
              <p className='main-light-text mb-0'>This note is specific to your work with {team?.teamName}</p>
            </div>
          </div>
          <div
            className='d-flex px-4 py-3 pointer action-state-hover'
            onClick={(e) => {
              setSelectedVisibility('agency');
              hideActions();
            }}
          >
            <div className='pt-1 analytics-share-icon-container' style={{ minWidth: '30px', marginTop: '-4px' }}>
              <OrganizationImage activePlan={activePlan} user={user} style={{ width: '30px', height: '30px' }} />
            </div>
            <div className='pl-3'>
              <p className='font-weight-bold mb-1'>General note</p>
              <p className='main-light-text mb-0'>This note is not specific to any particular client</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const OrganizationImage = ({ style = {}, activePlan, user }) => (
  <img
    style={{ width: '20px', height: '20px', ...style }}
    alt='avatar'
    className='rounded-circle img-fluid'
    src={activePlan?.organisationLogo ?? user.picture}
  />
);
const AgencyImage = ({ style = {}, team }) => (
  <img
    style={{ width: '20px', height: '20px', ...style }}
    alt='avatar'
    className='rounded-circle img-fluid'
    src={team?.organisationLogo ?? `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`}
  />
);

export { ProfileImage, BioElementsTabs, TagsSection, OrganizationImage, AgencyImage, AgencyLogoAndExplanation };
export default BioElements;
