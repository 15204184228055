import axios from 'axios';
import { useEffect } from 'react';

const useReloadFeed = (props) => {
  const { createResults, currentlyProcessing } = props;
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const callAgainResults = () => {
      setTimeout(async () => {
        try {
          await createResults({ pageNumber: false, setMarker: false, source });
        } catch (error) {
          console.error(error);
        }
      }, 5000);
    };
    const { state } = currentlyProcessing;
    if (state === false) {
      callAgainResults();
    }
    return () => {
      if (!currentlyProcessing.state && !!source) {
        source.cancel('New call canceled by the user.');
      }
    };
  }, [currentlyProcessing]);
};

export default useReloadFeed;
