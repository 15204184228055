import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { store } from 'components/Store.js';
import axios from 'axios';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer.js';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';
import { useCollapse } from 'react-collapsed';
import ResumeTopicEmailAlerts from './ResumeTopicEmailAlerts.js';
import CreateEmailAlert from './CreateEmailAlert.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import HideTopicCheckbox from './HideTopicCheckbox.js';
import preventLink from 'components/Dashboard/TagsList/prevenLink.js';
const TopicsLists = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists, team, activeSubscriptions, emailAlerts, scrollTopicPosition } = state;
  const [stateExpanded, setStateExpanded] = useState(false);
  const { maxListsPerTeam, planName, isPaidFor, allowImmediateAlerts, allowWeekdailyAlerts, allowWeeklyAlerts } =
    activeSubscriptions;
  const allowAlerts = allowImmediateAlerts && allowWeekdailyAlerts && allowWeeklyAlerts;
  const { myself } = team ?? {};
  const location = useLocation();
  const [emailAlertId, setEmailAlertId] = useState('');
  const [isOpenEditEmailAlert, setIsOpenEditEmailAlert] = useState(false);
  const [newEmailAlertTopic, setNewEmailAlertTopic] = useState({});
  const [openTopicEmailAlerts, setOpenTopicEmailAlerts] = useState();
  const [currentTopicAlerts, setCurrentTopicAlerts] = useState([]);

  const topicListScrollbar = useRef();
  const disabledConditional = maxListsPerTeam !== null && keywordsLists.length >= maxListsPerTeam;
  const navigate = useNavigate();
  const returnUrl = `${encodeURIComponent(`${location.pathname}${location.search}`)}`;
  const pricingUrl = `/pricing?returnUrl=${returnUrl}`;
  const { transformTopicOrClientWord, topicOrClientWord, agencyUser } = useTopicOrClientWord();

  const dataTip = disabledConditional
    ? planName === 'Mogul' || planName === 'Enterprise'
      ? `Click to purchase ${
          agencyUser ? 'coverage for' : ''
        } additional ${transformTopicOrClientWord({ plural: true })}`
      : `Upgrade to Mogul to add ${transformTopicOrClientWord({
          plural: true,
        })}`
    : '';
  const memberDataTip = () => {
    let tip =
      (myself?.role === 'Member' || myself?.role === 'Admin') && dataTip !== ''
        ? `Your account owner can purchase ${
            agencyUser ? 'coverage for' : ''
          } additional ${transformTopicOrClientWord({ plural: true })}`
        : dataTip;
    return tip;
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    let newKeywordLists = keywordsLists.filter((list) => list.id !== '');
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'keywordsLists',
      value: newKeywordLists,
    });
    if (scrollTopicPosition) {
      topicListScrollbar.current.scrollTop = scrollTopicPosition;
      dispatch({ type: 'MODIFY_SECTION', parameter: 'scrollTopicPosition', value: null });
    }
    return () => {
      source.cancel('Billing portal canceled by the user');
    };
  }, []);

  const [heightContainer, containerRef] = useHeightContainer();
  const arrowButton = useRef(null);

  const emailAlertsWhitoutThisTopic = () => {
    if (newEmailAlertTopic.id === '') {
      return [];
    } else {
      return emailAlerts.filter(
        (item) => !item.digestCombiningAllTopics && !item.keywordListIds.includes(newEmailAlertTopic.id)
      );
    }
  };

  const visibleKeywordsLists = keywordsLists.filter(
    (item) => (item.myState !== 'Hidden' && item.rememberedState !== 'Hidden') || item.rememberedState === 'Enabled'
  );
  const hiddenKeywordsLists = keywordsLists.filter(
    (item) => (item.myState === 'Hidden' && item.rememberedState !== 'Enabled') || item.rememberedState === 'Hidden'
  );

  return (
    <>
      <div ref={containerRef} />
      <div ref={topicListScrollbar} style={{ height: `${heightContainer}px` }} className='scroll-container'>
        <div>
          <div className='row mx-0 justify-content-between align-items-center padding-topic-component pt-lg-4 section-title pb-4 sticky-top background-white'>
            <h3 className='main-title topics-title mb-0'>
              {transformTopicOrClientWord({ uppercase: true, plural: true })} ({visibleKeywordsLists.length})
            </h3>
            <div className='d-flex align-items-center'>
              {keywordsLists.length > 1 && (
                <button
                  ref={arrowButton}
                  className={`general-button border collapse-expand-button action-state-hover mr-3 main-subtle-text ${
                    stateExpanded ? 'collapse-button' : ''
                  }`}
                  onClick={() => {
                    setStateExpanded(!stateExpanded);
                  }}
                >
                  {stateExpanded ? 'Expand' : 'Collapse'} all
                </button>
              )}
              <button
                data-tooltip-content={memberDataTip()}
                data-tooltip-id='settings-tooltip'
                className={`general-button action-button px-3 py-2 ${disabledConditional ? 'topic-block-button' : ''}`}
                onClick={async () => {
                  if (!disabledConditional) {
                    navigate(
                      `/settings/${transformTopicOrClientWord({
                        plural: true,
                      })}/new-${topicOrClientWord}`
                    );
                  } else {
                    if (myself?.role !== 'Member' && myself?.role !== 'Admin') {
                      if (isPaidFor) {
                        navigate(`/settings/billing?returnUrl=${returnUrl}`);
                      } else {
                        navigate(pricingUrl);
                      }
                    }
                  }
                }}
              >
                Add a {topicOrClientWord}
              </button>
            </div>
          </div>
          {visibleKeywordsLists.map((item) => {
            const { id } = item;
            return (
              <TopicList
                key={`list${id}`}
                item={item}
                stateExpanded={stateExpanded}
                setNewEmailAlertTopic={setNewEmailAlertTopic}
                setOpenTopicEmailAlerts={setOpenTopicEmailAlerts}
                setCurrentTopicAlerts={setCurrentTopicAlerts}
                setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
                allowAlerts={allowAlerts}
                keywordsLists={keywordsLists}
                myself={myself}
                topicListScrollbar={topicListScrollbar}
              />
            );
          })}
        </div>
        {hiddenKeywordsLists.length > 0 && (
          <>
            <div className='row mx-0 justify-content-between align-items-center padding-topic-component pt-lg-4 section-title pb-4 sticky-top background-white'>
              <h3 className='main-title topics-title mb-0'>
                Hidden {transformTopicOrClientWord({ uppercase: false, plural: true })} ({hiddenKeywordsLists.length})
              </h3>
            </div>
            <div>
              {hiddenKeywordsLists.map((item) => {
                const { id } = item;
                return (
                  <TopicList
                    key={`list${id}`}
                    item={item}
                    stateExpanded={stateExpanded}
                    setNewEmailAlertTopic={setNewEmailAlertTopic}
                    setOpenTopicEmailAlerts={setOpenTopicEmailAlerts}
                    setCurrentTopicAlerts={setCurrentTopicAlerts}
                    setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
                    allowAlerts={allowAlerts}
                    keywordsLists={keywordsLists}
                    myself={myself}
                    topicListScrollbar={topicListScrollbar}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      <CreateEmailAlert
        openCreateEmailAlert={isOpenEditEmailAlert}
        emailAlertId={emailAlertId}
        setEmailAlertId={setEmailAlertId}
        setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
        topicData={{
          topicId: newEmailAlertTopic.id,
          name: newEmailAlertTopic.name,
        }}
        manageEmailAlerts={emailAlerts.length === 0}
        openCreatingNewTopic={emailAlerts.length > 0}
        emailAlertsToSelect={emailAlertsWhitoutThisTopic()}
      />
      <ResumeTopicEmailAlerts
        setOpenTopicEmailAlerts={setOpenTopicEmailAlerts}
        currentTopicAlerts={currentTopicAlerts}
        openTopicEmailAlerts={openTopicEmailAlerts}
      />
    </>
  );
};

const AdvancedRulesButton = (props) => {
  const { item } = props;
  const { keywordExclusions, andKeywords } = item;
  let numberOfItems = 0;
  if (keywordExclusions.length > 0 && andKeywords.length > 0) {
    numberOfItems = 2;
  } else if (keywordExclusions.length > 0 || andKeywords.length > 0) {
    numberOfItems = 1;
  }
  return (
    <>
      {numberOfItems > 0 && (
        <div className='keywords-adjustment py-2 border-top advanced-rules-button'>
          <p className='mb-0 paragraph-p3 mr-3'>
            + {numberOfItems} advanced rule{numberOfItems > 1 ? 's' : ''}
          </p>
        </div>
      )}
    </>
  );
};

const TopicList = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { emailAlerts } = state;
  const {
    item,
    stateExpanded,
    setNewEmailAlertTopic,
    setIsOpenEditEmailAlert,
    setOpenTopicEmailAlerts,
    setCurrentTopicAlerts,
    allowAlerts,
    keywordsLists,
    topicListScrollbar,
  } = props;
  const { name, id, keywords, description } = item;
  const isMobile = useIsMobile();
  const location = useLocation();

  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  useEffect(() => {
    setExpanded(!stateExpanded);
  }, [stateExpanded]);
  const { topicOrClientWord, transformTopicOrClientWord } = useTopicOrClientWord();

  return (
    <div
      className='topic-list-item pt-4 pb-3'
      {...getToggleProps({
        onClick: () => setExpanded((prevExpanded) => !prevExpanded),
      })}
    >
      <div
        className={`d-flex justify-content-between align-items-center flex-wrap topic-individual-padding ${
          !description && isExpanded ? 'mb-3' : ''
        }`}
      >
        <h4 className={`topic-list-item-name mb-0`}>
          <span className='icon-topics-thin'></span>
          <span className={`dropdown-item-element hidden-lines hidden-one-line ${isExpanded ? 'open' : ''}`}>
            {name}
          </span>
        </h4>
        <div className='d-flex align-items-center email-alerts-badges topic-badges-margin-adjustment'>
          {!isMobile && <HideTopicCheckbox keywordsLists={keywordsLists} item={item} topicsPage rememberState />}
          <EmailAlertsForTopic
            setNewEmailAlertTopic={setNewEmailAlertTopic}
            setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
            setOpenTopicEmailAlerts={setOpenTopicEmailAlerts}
            setCurrentTopicAlerts={setCurrentTopicAlerts}
            item={item}
            allowAlerts={allowAlerts}
            emailAlerts={emailAlerts}
          />
          <Link
            to={`/settings/${transformTopicOrClientWord({
              plural: true,
            })}/${id}${location.search}`}
            className='d-block text-right'
            onClick={() => {
              dispatch({
                type: 'MODIFY_SECTION',
                parameter: 'scrollTopicPosition',
                value: topicListScrollbar?.current?.scrollTop,
              });
            }}
          >
            <button className='general-button outline-active-button main-light-text paragraph-p3 edit-email-alert-button'>
              Edit <span className='d-none d-md-inline-block'>{topicOrClientWord}</span>
              <span className='icon-edit-pencil'></span>
            </button>
          </Link>
        </div>
      </div>
      <div
        className='keywords-description-topic overflow-hidden topic-individual-padding'
        {...getCollapseProps()}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {!!description && <p className='main-light-text mb-3 mt-1'>{description}</p>}

        <div className='email-alert-information-box'>
          <div className='keywords-adjustment individual-items-vertical-padding'>
            <p className='mb-1 no-select-item individual-topic-subtitle'>Keywords and phrases</p>
            <p className='mb-3 mb-lg-0 individual-topic-actual-text'>{keywords.join(', ')}</p>
          </div>
          <AdvancedRulesButton item={item} />
        </div>
        {isMobile && <HideTopicCheckbox keywordsLists={keywordsLists} item={item} topicsPage rememberState />}
      </div>
    </div>
  );
};

const EmailAlertsForTopic = (props) => {
  const {
    item,
    allowAlerts,
    setNewEmailAlertTopic,
    setIsOpenEditEmailAlert,
    setOpenTopicEmailAlerts,
    setCurrentTopicAlerts,
    emailAlerts,
  } = props;
  const { id } = item;
  const numberOfEmailAlerts = emailAlerts.filter(
    (item) => item.keywordListIds.includes(id) || item.digestCombiningAllTopics
  );

  return (
    <EmailAlertBadge
      emailAlerts={numberOfEmailAlerts}
      allowAlerts={allowAlerts}
      clickFunction={() => {
        if (allowAlerts) {
          setNewEmailAlertTopic(item);
          if (numberOfEmailAlerts.length === 0) {
            setIsOpenEditEmailAlert(true);
          } else {
            setOpenTopicEmailAlerts(true);
            setCurrentTopicAlerts(numberOfEmailAlerts);
          }
        }
      }}
    />
  );
};

const EmailAlertBadge = (props) => {
  const { clickFunction, allowAlerts, emailAlerts } = props;
  const includeSlackAlert = emailAlerts?.filter((item) => item.alertTypes.includes('Slack'))?.length > 0;
  const includeEmailAlert = emailAlerts?.filter((item) => item.alertTypes.includes('Email'))?.length > 0;

  const isMobile = useIsMobile();
  return (
    <button
      className={`general-button rounded-button outline-badge d-inline-block paragraph-p3 badge-spacing mr-2 mr-lg-3 hovered-button badge-email-alert ${
        emailAlerts?.length === 0 ? 'no-email-alerts-badge' : ''
      } ${allowAlerts ? '' : 'no-email-alerts-blocked'}`}
      onClick={(e) => {
        preventLink(e);
        clickFunction();
      }}
      data-tooltip-content={allowAlerts ? '' : 'Email alerts are available on Pro and Mogul'}
      data-tooltip-id={'settings-tooltip'}
      data-place={'top'}
    >
      {emailAlerts?.length === 0 && (
        <span className='topics-item-name_alert'>
          <span className={`icon-${allowAlerts ? 'email' : 'padlock'} paragraph-p6 mr-2`} />
          {isMobile ? '0' : 'No email alerts'}
        </span>
      )}
      {emailAlerts?.length > 0 && (
        <span className='topics-item-name_alert'>
          {allowAlerts ? (
            <>
              {includeEmailAlert && (
                <span className={`icon-email text-main-blue paragraph-p6`} style={{ marginRight: '5px' }} />
              )}{' '}
              {includeSlackAlert && (
                <img
                  style={{
                    verticalAlign: 'middle',
                    width: '14px',
                    margin: '-3px 5px 0px 0px',
                  }}
                  className='d-inline-block'
                  src={`${process.env.REACT_APP_CDNURL}/images/20230716-slack-icon.svg`}
                  alt='slack logo'
                />
              )}
            </>
          ) : (
            <span className={`icon-padlock mr-2`} />
          )}
          <span>{emailAlerts?.length}</span>{' '}
          <span className='d-none d-lg-inline-block'>alert{emailAlerts?.length > 1 ? 's' : ''}</span>
        </span>
      )}
    </button>
  );
};

export { EmailAlertBadge, AdvancedRulesButton };
export default TopicsLists;
