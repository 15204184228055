import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from 'components/Store.js';
import axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { Link, useNavigate } from 'react-router-dom';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';

const AddTopicButton = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const location = useLocation();
  const { activePlan, activeSubscriptions, team } = state;
  const { keywordsLists, settingsPage, setStartMoveLists, whitePaddlock, client } = props;
  const { myself } = team ?? {};

  const { maxListsPerTeam } = settingsPage ? activeSubscriptions : activePlan ?? {};
  const planName = settingsPage ? activeSubscriptions.planName : activePlan?.name ?? '';
  const disabledConditional = maxListsPerTeam !== null && keywordsLists.length >= maxListsPerTeam;

  const isPaidFor = settingsPage
    ? activeSubscriptions?.isPaidFor
    : !!activePlan
      ? activePlan?.subscriptionStatus === 'Subscribed' || activePlan?.subscriptionStatus === 'SubscribedOverLimit'
      : false;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const navigate = useNavigate();

  let keywordsListsWithoutMasterTopic = keywordsLists.filter((item) => item.id !== null);
  const { topicOrClientWord, transformTopicOrClientWord, agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const memberDataTip = () => {
    let dataTip = disabledConditional
      ? planName === 'Mogul' || planName === 'Enterprise'
        ? `Click to purchase ${agencyUser ? 'coverage for' : ''} additional ${transformTopicOrClientWord({ plural: true })}`
        : `Upgrade to Mogul to add ${transformTopicOrClientWord({ plural: true })}`
      : '';
    if (newAgencyPlan) {
      dataTip = 'You have reached the limit of topics you can add for this client. Contact us to add more';
    }
    let tip =
      (myself.role === 'Member' || myself.role === 'Admin') && dataTip !== ''
        ? `Your account owner can purchase ${agencyUser ? 'coverage for' : ''} additional ${transformTopicOrClientWord({ plural: true })}`
        : dataTip;
    return tip;
  };

  useEffect(() => {
    return () => {
      source.cancel('Billing portal cancel by the user');
    };
  }, []);

  const hoverAddTopicButton = () => {
    if (!!setStartMoveLists) {
      setStartMoveLists(false);
    }
  };

  const isMobile = useIsMobile();
  return (
    <>
      {newAgencyPlan && keywordsLists?.length > 0 && !client ? (
        <>
          <Link to={`/settings/clients?teamId=${team?.teamId}`}>
            <button
              className='general-button add-keyword-list-button manage-topics-client-button '
              onMouseOver={hoverAddTopicButton}
            >
              <span className='hidden-lines hidden-one-line pr-2'>
                <span className='icon-edit-pencil mr-2 ' /> Manage topics for {team?.teamName}
              </span>
            </button>
          </Link>
        </>
      ) : (
        <>
          {(keywordsListsWithoutMasterTopic.length < maxListsPerTeam || maxListsPerTeam === null) && (
            <Link
              to={`/settings/${transformTopicOrClientWord({ plural: true })}/new-${topicOrClientWord}${!settingsPage ? `?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}` : `${!!client ? `${settingsPage ? '?' : '&'}teamId=${client?.teamId}` : ''}`}`}
            >
              <button className='general-button add-keyword-list-button' onMouseOver={hoverAddTopicButton}>
                <span className='hidden-lines hidden-one-line'>
                  <span className='icon-square-plus' />{' '}
                  {keywordsListsWithoutMasterTopic.length === 0 ? 'Create' : 'Add'} a {topicOrClientWord}{' '}
                  {newAgencyPlan ? ` for ${client ? client?.teamName : team?.teamName}` : ''}
                </span>
              </button>
            </Link>
          )}
          {maxListsPerTeam !== null && keywordsListsWithoutMasterTopic.length >= maxListsPerTeam && (
            <Link
              className={`add-more-button add-more-lists mt-0 ${newAgencyPlan ? 'contact-button-launch-messenger' : ''}`}
              data-tooltip-id='add-topic-button-tooltip'
              data-tooltip-content={Object.keys(team).length > 0 ? memberDataTip() : ''}
              data-tooltip-float={true}
              data-tooltip-place={'top'}
              to={'/'}
              onClick={async (e) => {
                e.preventDefault();
                const returnUrl = `${location.pathname}${location.search}${location.hash}`;
                if (myself.role !== 'Member' && myself.role !== 'Admin' && !newAgencyPlan) {
                  if (isPaidFor) {
                    navigate(`/settings/billing?returnUrl=${returnUrl}`);
                  } else {
                    navigate(`/pricing?returnUrl=${returnUrl}`);
                  }
                }
              }}
            >
              <button className='general-button add-keyword-list-button' onMouseOver={hoverAddTopicButton}>
                <span className='icon-square-plus' /> Add a {topicOrClientWord}{' '}
                {newAgencyPlan ? `for ${client ? client?.teamName : team?.teamName}` : ''}
                <img
                  src={`${process.env.REACT_APP_CDNURL}/images/padlock-${whitePaddlock ? 'white' : 'lightblue'}.svg`}
                  alt='padlock'
                />
              </button>
            </Link>
          )}
        </>
      )}
      <ReactTooltip
        id={'add-topic-button-tooltip'}
        effect={'solid'}
        type={'info'}
        className={'pricing-feature-tooltip feed-tooltip'}
        arrowColor={'transparent'}
        backgroundColor={'#00122B'}
        multiline={true}
        globalEventOff={isMobile ? 'click' : undefined}
      />
    </>
  );
};

export default AddTopicButton;
