import { useContext, useEffect, useRef, useState } from 'react';
import ModalComponent from 'components/Common/Modal';
import ItemsPalette from '@cloudscape-design/board-components/items-palette';
import { boardItemI18nStrings, paletteI18nStrings } from './i18n-strings';
import normalize from 'components/Dashboard/utilities/normalizeString';
import BoardItem from '@cloudscape-design/board-components/board-item';
import DescriptionTinyEditor from 'components/Dashboard/UserContent/ui/DescriptionTinyEditor';
import createMarkup from 'components/Dashboard/helpers/createMarkup';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { init } from 'emoji-mart';
import useReportIconAutoselect from 'components/Reports/useReportIconAutoselect';
import { store } from 'components/Store';
import { useAuth0 } from '@auth0/auth0-react';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import parliamentarianUsers from 'components/Dashboard/SidebarElements/parliamentarianUsers';
import checkEllipsis from 'components/Dashboard/utilities/checkEllipsis';
import { Portal } from 'react-portal';

const MacrosList = (props) => {
  const { layout, macrosState, setLayout } = props;
  const { macrosList, setMacrosList } = macrosState ?? {};
  const [isOpen, setIsOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [macroState, setMacroState] = useState('new');

  const { generalApiCall } = useGeneralApiCall();
  const { newAgencyPlan } = useTopicOrClientWord();

  useEffect(() => {
    init({ data });
  }, []);

  const createEditMacro = async ({ id, name, content, icon }) => {
    try {
      const pathname = `/api/report-macro/${!!id ? 'update' : 'create'}`;
      const method = 'post';
      let requestProperties = {
        name,
        content,
        icon,
      };
      if (!!id) {
        requestProperties = {
          ...requestProperties,
          id,
        };
      }
      const macro = await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
      if (macro) {
        let newMacros = [...macrosList];
        if (id) {
          let itemPosition = newMacros.findIndex((item) => item.data.id === macro.id);
          let newItem = { ...newMacros[itemPosition] };
          newItem = {
            ...newItem,
            data: {
              ...newItem.data,
              ...macro,
              state: { value: macro?.content, widgetName: macro?.name, type: 'macro' },
            },
          };
          newMacros[itemPosition] = newItem;
        } else {
          let newMacro = {
            id: `macro-${macro?.id}`,
            definition: { minColumnSpan: 2, minRowSpan: 2, defaultRowSpan: 2, defaultColumnSpan: 2 },
            data: {
              ...macro,
              component: 'Commentary',
              state: { value: macro?.content, widgetName: macro?.name, type: 'macro' },
            },
          };
          newMacros = [newMacro, ...macrosList];
          setLayout([
            { ...newMacro, id: `macro-created-${macro?.id}`, rowSpan: 2, columnSpan: 2, columnOffset: { 4: 0 } },
            ...layout,
          ]);
        }
        setMacrosList(newMacros);
      }
    } catch (error) {}
  };

  const deleteMacro = async ({ macro }) => {
    try {
      const pathname = `/api/report-macro/${macro?.data?.id}`;
      const method = 'delete';
      const result = await generalApiCall({ pathname, method, needsAuthentication: true, returnCompleteRequest: true });
      if (result.status === 200) {
        createNotification('success', 'Macro has been deleted successfully');
        return true;
      }
    } catch (error) {}
  };

  const createMacrosList = () => {
    return macrosList.map((item) => {
      let newId = item?.id;
      // eslint-disable-next-line
      while (layout.find((widget) => widget.id === newId)) {
        newId += '0';
      }
      return { ...item, id: newId, title: item.data?.name };
    });
  };

  const macros = createMacrosList();
  const actionsElement = useRef();
  const hideActions = () => {
    setShowActions(false);
  };

  useOutsideElement(actionsElement, hideActions);
  const macrosActionsButton = useRef();
  const { width, top, left, height } = macrosActionsButton?.current?.getBoundingClientRect() ?? {};
  return (
    <>
      <div className='flex-centered mt-4 mb-3'>
        <h3 className='title-h5-m-bold sidenav-subtitle mb-0'>
          Macros
          {macros?.length === 0 && (
            <span
              className='icon-info main-light-text d-inline-block-centered centered-2 ml-2 paragraph-p3'
              data-tooltip-html={`A macro is a prepared piece of text<br/> that can be applied to any report ${newAgencyPlan ? '<br/>created for this client' : ''}`}
              data-tooltip-id={'general-tooltip'}
            />
          )}
        </h3>
        {macros?.length > 0 && (
          <div className='position-relative z-max ml-1' ref={actionsElement}>
            <button
              className={`indented-button general-button circle-button item-actions-button ${showActions ? 'active' : ''}`}
              data-tooltip-content={showActions ? '' : 'Manage macros'}
              data-tooltip-id={'general-tooltip'}
              onClick={(e) => {
                setShowActions(!showActions);
              }}
              style={{ marginTop: '1px' }}
              ref={macrosActionsButton}
            >
              Item actions
            </button>
            {showActions && (
              <div
                className='box box-shadow py-2 item-actions-box reports-actions-box position-fixed'
                style={{
                  top: top + height / 2 - 45,
                  left: left + width + 10,
                }}
              >
                <button
                  className='general-button text-red-warning flex-centered'
                  onClick={() => {
                    setMacroState('new');
                    setShowActions(false);
                    setIsOpen(true);
                  }}
                >
                  <span className='mr-2 d-inline-block-centered macros-plus-icon'>+</span> <span>Create new</span>
                </button>

                <button
                  className='general-button text-red-warning'
                  onClick={() => {
                    setMacroState('manage');
                    setShowActions(false);
                    setIsOpen(true);
                  }}
                >
                  <span className='icon-edit-bold d-inline-block-centered macros-manage-icon' />
                  Manage macros
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <ul className='px-0 mb-4 reports-side-nav'>
        {macros.length === 0 && (
          <button
            className='general-button px-5 simple-link-button mb-2 flex-centered reports-create-macro-button'
            onClick={() => {
              setMacroState('new');
              setIsOpen(true);
            }}
          >
            <span className='title-h2 mr-2 macro-add'>+</span> Create new
          </button>
        )}
        <ItemsPalette
          items={macros}
          i18nStrings={paletteI18nStrings}
          renderItem={(item) => {
            const { name, icon } = item.data;
            return (
              <BoardItem i18nStrings={boardItemI18nStrings} header={<MacrosListHeader name={name} icon={icon} />} />
            );
          }}
        />
      </ul>
      <MacrosPopup
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        createEditMacro={createEditMacro}
        macros={macros}
        macroState={macroState}
        setMacroState={setMacroState}
        deleteMacro={deleteMacro}
        setMacrosList={setMacrosList}
        macrosList={macrosList}
      />
    </>
  );
};

const MacrosListHeader = (props) => {
  const { name, icon } = props;
  const nameItem = useRef();

  return (
    <div className={`sidebar-item sidebar-item-report ${normalize(name)}-a `}>
      <div className='flex-centered'>
        <ListIcon icon={icon} />
        <span
          className='hidden-lines hidden-one-line'
          ref={nameItem}
          data-tooltip-content={checkEllipsis(nameItem?.current) ? name : ''}
          data-tooltip-id={'general-tooltip'}
        >
          {name}
        </span>
      </div>
    </div>
  );
};

const ListIcon = (props) => {
  const { icon } = props;
  return (
    <span>
      {icon?.startsWith('https://') ? (
        <img className='macro-list-image' src={icon} alt='Macro identifier' />
      ) : (
        <span className='macro-icon-container'>
          <em-emoji set={'apple'} id={icon ?? 'spiral_note_pad'} size={'1.25em'} style={{ lineHeight: 1 }} />
        </span>
      )}
    </span>
  );
};

const MacrosPopup = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state ?? {};

  const {
    isOpen,
    setIsOpen,
    createEditMacro,
    macros,
    deleteMacro,
    macroState,
    setMacroState,
    macrosList,
    setMacrosList,
  } = props;
  const [selectedColor, setSelectedColor] = useState('676a75');
  const [macro, setMacro] = useState({ name: '', content: '', icon: 'spiral_note_pad' });
  const [showBackButton, setShowBackButton] = useState(false);
  const [macroContent, setMacroContent] = useState('');
  const inputRef = useRef();
  const [loadingStates, setLoadingStates] = useState({ createEdit: false, delete: false });

  const { newAgencyPlan } = useTopicOrClientWord();

  const editorRef = useRef(null);
  const editorToEdit = useRef();

  const keyupfunction = () => {
    const { value, selectedColor } = editorRef.current.props ?? {};
    if (value === '' && selectedColor !== '676a75') {
      editorToEdit.current.execCommand('mceApplyTextcolor', 'forecolor', `#${selectedColor}`);
    }
  };

  const closePopup = (value) => {
    setIsOpen(value);
    setSelectedColor('#676a75');
    editorToEdit.current?.execCommand('mceApplyTextcolor', 'forecolor', `#676a75`);
    setTimeout(() => {
      setMacro({ name: '', icon: 'spiral_note_pad' });
      setMacroContent('');
      setShowBackButton(false);
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, [isOpen, macroState]);
  return (
    <ModalComponent isOpen={isOpen} setIsOpen={closePopup} maxWidth={700}>
      <>
        {macroState === 'new' ? (
          <div className={`px-3 px-lg-5 py-4 py-lg-5`}>
            {showBackButton && (
              <div style={{ marginTop: '-25px' }}>
                <button
                  className='text-light-blue general-button rounded-button px-0 py-2'
                  onClick={() => {
                    setMacroState('manage');
                  }}
                >
                  <span className='icon-long-arrow-left mr-2 paragraph-p5' />
                  Back
                </button>
              </div>
            )}
            <MacroName macro={macro} setMacro={setMacro} />
            <div className='form-group mb-4'>
              <label className='title-h5-m-bold mb-3'>Macro content</label>
              <div className='influence-description position-relative macros-commentary'>
                <DescriptionTinyEditor
                  ref={editorRef}
                  editorToEdit={editorToEdit}
                  value={macroContent}
                  setValue={(value) => setMacroContent(value)}
                  placeholder='Start typing'
                  reportWidget={true}
                  minHeight={300}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  keyupfunction={keyupfunction}
                  addVerticalScroll
                />
              </div>
            </div>

            <div className='text-right'>
              <button
                className='px-4 py-2 general-button action-button'
                disabled={macro.name === '' || macroContent === '' || loadingStates?.createEdit}
                onClick={async () => {
                  setLoadingStates({ ...loadingStates, createEdit: true });
                  await createEditMacro({ ...macro, content: macroContent });
                  setLoadingStates({ ...loadingStates, createEdit: false });
                  closePopup(false);
                }}
              >
                {loadingStates?.createEdit && <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />}
                {!!macro?.id ? 'Save' : 'Create and insert'} macro
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className='pt-4 pb-4 pt-lg-5 px-3 pr-lg-4 pl-lg-5'>
              <div className='pt-2 flex-centered justify-content-between'>
                <h3 className='title-h5-m-bold mb-0'>Manage macros {newAgencyPlan ? `for ${team?.teamName}` : ''}</h3>

                <button
                  onClick={() => {
                    setMacroState('new');
                    setShowBackButton('true');
                  }}
                  className='general-button action-button'
                  style={{ padding: '0.35em 1.5em' }}
                >
                  Create new
                </button>
              </div>
            </div>
            <div className='overflow-auto px-3 pr-lg-4 pl-lg-5 pb-4 pb-lg-5' style={{ maxHeight: '73vh' }}>
              {macros?.map((item, index) => {
                return (
                  <MacroPopupItem
                    key={`item-${item?.id ?? item?.name}`}
                    item={item}
                    setMacro={setMacro}
                    deleteMacro={deleteMacro}
                    setMacroState={setMacroState}
                    setMacroContent={setMacroContent}
                    closePopup={closePopup}
                    macros={macros}
                    index={index}
                    macrosList={macrosList}
                    setMacrosList={setMacrosList}
                  />
                );
              })}
            </div>
          </>
        )}
      </>
    </ModalComponent>
  );
};

const MacroPopupItem = (props) => {
  const {
    item,
    setMacro,
    deleteMacro,
    setMacroState,
    closePopup,
    macros,
    index,
    setMacroContent,
    macrosList,
    setMacrosList,
  } = props;
  const { name, icon } = item?.data;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className={`flex-centered justify-content-between ${index > 0 ? 'pt-4' : ''}`}>
      <p className='mb-0 flex-grow-1 flex-centered'>
        <ListIcon icon={icon} />
        <span className='hidden-lines hidden-one-line'>{name}</span>
      </p>
      <div className='pl-2 flex-centered justify-content-end' style={{ minWidth: '80px' }}>
        <button
          className='general-button circle-button hovered-button'
          data-tooltip-content='Edit macro'
          data-tooltip-id='modal-tooltip'
          onClick={() => {
            setMacro({ ...item?.data, icon: item?.data?.icon ?? 'spiral_note_pad' });
            setMacroContent(item?.data?.content);
            setMacroState('new');
          }}
        >
          <span className='icon-edit-bold' />
        </button>
        <button
          className='general-button circle-button hovered-button ml-1'
          data-tooltip-content='Delete macro'
          data-tooltip-id='modal-tooltip'
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            let deleteItem = await deleteMacro({ macro: item });
            if (deleteItem) {
              if (macros.length === 1) {
                closePopup();
              }
              setTimeout(() => {
                let newMacros = [...macrosList];
                let itemPosition = newMacros.findIndex((macro) => macro.data.id === item.data.id);
                newMacros.splice(itemPosition, 1);
                setMacrosList(newMacros);
              }, 200);
            }
          }}
        >
          {isLoading ? (
            <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />
          ) : (
            <span className='icon-delete paragraph-p2' />
          )}
        </button>
      </div>
    </div>
  );
};

const MacroName = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { teamName, code } = activePlan;

  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const { user } = useAuth0();

  const { macro, setMacro } = props;
  const inputRef = useRef();
  const searchTimeout = useRef();
  const { callSuggestionIcon } = useReportIconAutoselect();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, []);

  const onBlurFunction = async (e) => {
    const inputValue = macro?.name;
    if (inputValue !== '') {
      let value = await callSuggestionIcon({ value: inputValue });
      if (value) {
        let finalValue = value === 'logotype' ? activePlan?.organisationLogo ?? null : value;
        if (finalValue) {
          setMacro({ ...macro, name: e.target.value, icon: finalValue });
        }
      }
    }
  };

  const createPlaceHolder = () => {
    let placeholder = `E.g. 'About `;
    if (parliamentarianUsers(code)) {
      placeholder += `${user?.name}'`;
    } else if (newAgencyPlan) {
      placeholder = `E.g.  Our approach to working with ${teamName}`;
    } else if (!!teamName && !agencyUser) {
      placeholder += `${teamName.includes('@') ? 'your organisation' : teamName}'`;
    } else {
      placeholder += `your organisation'`;
    }
    return placeholder;
  };

  return (
    <>
      <div className='position-relative from-group mb-4 report-create-macro-name mt-2'>
        <label className='title-h5-m-bold mb-3'>Macro name</label>
        <div className='border rounded flex-centered py-1 px-3'>
          <IconPicker
            icon={macro?.icon}
            iconClass={'macro-icon-selector'}
            onEmojiSelect={(emoji) => {
              setMacro({ ...macro, icon: emoji.id });
            }}
          />
          <div className='flex-grow-1'>
            <input
              ref={inputRef}
              value={macro.name}
              onChange={(e) => setMacro({ ...macro, name: e.target.value })}
              id='macroName'
              type='text'
              className='input-empty form-control pl-2'
              placeholder={createPlaceHolder()}
              autoComplete='off'
              onKeyUp={(e) => {
                if (searchTimeout.current) clearTimeout(searchTimeout.current);
                searchTimeout.current = setTimeout(() => onBlurFunction(e), 500);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const MacroItem = (props) => {
  const { item } = props;
  const { content } = item?.data ?? {};
  const container = useRef();

  const [itemHeight, setItemHeight] = useState(0);
  useEffect(() => {
    const parent = container.current?.parentElement?.parentElement?.parentElement?.parentElement;
    const prevSibling = container.current?.parentElement?.previousElementSibling;
    setItemHeight(parent?.getBoundingClientRect()?.height - (prevSibling?.getBoundingClientRect()?.height + 24));
  }, [container]);

  return (
    <div
      ref={container}
      className='paragraph-p1'
      style={{ height: `${itemHeight}px` }}
      dangerouslySetInnerHTML={createMarkup(content)}
    />
  );
};

const IconPicker = (props) => {
  const { icon, onEmojiSelect, iconClass = '', element } = props;
  const [openPicker, setOpenPicker] = useState(false);
  const emojiContainer = useRef();
  const emojiElement = useRef();

  const { top, left, height } = emojiElement.current?.getBoundingClientRect() ?? {};
  return (
    <>
      {openPicker && <div className='general-close-background' onClick={() => setOpenPicker(false)} />}
      <div className={`position-relative z-max ${iconClass}`} ref={emojiContainer}>
        <span className='emoji-selector pointer' ref={emojiElement} onClick={() => setOpenPicker(!openPicker)}>
          {!!element ? (
            <>{element()}</>
          ) : (
            <>
              {icon?.startsWith('https://') ? (
                <img src={icon} alt='Macro identifier' />
              ) : (
                <em-emoji set={'apple'} id={icon} size={'1.45em'} style={{ lineHeight: 1 }} />
              )}
            </>
          )}
        </span>
        {openPicker && (
          <Portal>
            <div
              className='macro-emoji-picker adjustment-of-position'
              style={{ position: 'fixed', top: `${top + height}px`, left: `${left}px` }}
            >
              <Picker
                data={data}
                onEmojiSelect={(emoji) => {
                  onEmojiSelect(emoji);
                  setOpenPicker(false);
                }}
                previewPosition={'none'}
                skinTonePosition={'none'}
              />
            </div>
          </Portal>
        )}
      </div>
    </>
  );
};

export { MacroItem, IconPicker, ListIcon };
export default MacrosList;
