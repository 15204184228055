import { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../Store';
import useGeneralRequestProperties from '../hooks/useGeneralRequestProperties';
import Axios from 'axios';
import { createSectionName } from '../helpers/controlActiveFilters';
import { normalizedDateUK } from '../utilities/normalizedDateUK';
import getUrlParam from '../utilities/getUrlParam';
import useCreateResults from '../ListControl/useCreateResults';
import dayjs from 'dayjs';

const useCallParliamentLive = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;
  const { createRequestProperties } = useGeneralRequestProperties();
  const sectionName = createSectionName();
  const { createResults } = useCreateResults({});
  const defaultInterval = 30000; //ms
  //OP: temporary code
  const dateParam = getUrlParam('startDateTime');
  const minutesPerCallParam = getUrlParam('minutesPerCall');
  const minutesPerCall = minutesPerCallParam ?? defaultInterval / 60000;
  const interval = minutesPerCallParam ? 60000 : defaultInterval;
  const [date, setDate] = useState(normalizedDateUK(dateParam).add(minutesPerCall, 'minute').format());
  //

  const intervallCallFunction = useRef();
  intervallCallFunction.current = async ({ source }) => {
    try {
      const directTopic = getUrlParam('topic-id') === 'all' ? keywordsLists?.filter((item) => !!item.id)?.[0] : null;
      const { requestProperties } = createRequestProperties({
        pageNumber: 1,
        directTopic,
        noUseQuery: false,
        customPageSize: 1000,
      });
      let newRequestProperties = { ...requestProperties };
      const newDateFilter = {
        field: 'dateTime',
        //OP: hardcoded value
        value: dayjs
          .utc(normalizedDateUK(new Date()))
          .startOf('day')
          .add(dateParam ? 0 : -minutesPerCall, 'minute')
          .format(), //
        operator: 'DATE_GTE',
      };
      let newFilters = [...newRequestProperties.filters];
      newFilters = [...newFilters.filter((item) => item?.field !== 'dateTime'), newDateFilter];

      //OP: temporary code
      if (dateParam) {
        newFilters = [
          ...newFilters,
          {
            field: 'dateTime',
            value: normalizedDateUK(date).add(minutesPerCall, 'minute').format(),
            operator: 'date_lt',
          },
        ];
      }

      newRequestProperties = { ...newRequestProperties, filters: newFilters };
      await createResults({
        pageNumber: 1,
        setMarker: false,
        source,
        notForceLoading: true,
        requestProperties: newRequestProperties,
        customPageSize: 1000,
      });
      //OP: temporary code
      setDate(normalizedDateUK(date).add(minutesPerCall, 'minute'));
      //
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    let intervalFunc;
    if (sectionName === 'ParliamentLive') {
      intervalFunc = setInterval(() => intervallCallFunction.current({ source }), interval);
    }
    return () => {
      if (!!source) {
        source.cancel('New call canceled by the user.');
      }
      if (!!intervalFunc) {
        clearInterval(intervalFunc);
      }
      //OP: temporary code
      setDate(dateParam ?? normalizedDateUK(new Date()));
      //
    };
  }, [sectionName]);
};

export default useCallParliamentLive;
