import React, { useEffect, useRef } from 'react';
import MenuItems from 'components/embed/MenuItems';
import RelatedContentItem from './RelatedContentItem';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { formatGeneralDate } from 'components/Dashboard/helpers/formatGeneralDate';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import normalize from 'components/Dashboard/utilities/normalizeString';
import TweetItem from 'components/Dashboard/SocialMedia/TweetItem';
import { relatedItemsIconMapping } from 'components/ContentRelated';

const SearchRelatedContent = (props) => {
  const {
    searchValue,
    searchFunction,
    setSearchValue,
    loadingRelatedSuggestions,
    relatedSearchResults,
    typeOfContent,
    selectResource,
    type,
    value,
    deleteItemFunction,
    associatedContent,
    inModal,
    insertContentLink,
    customText,
    onScrollFunction,
    enabledSwiper = true,
  } = props;
  const inputRef = useRef(null);
  const currentRelatedContent = value;

  const userContentType = type === 'policy-asks' ? 'policy ask' : type?.replace('-', ' ');

  useEffect(() => {
    if (!!inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
    }
  }, [inputRef.current]);

  return (
    <>
      <div className={`search-related-content-container topic-email-alerts-content px-4 px-lg-5 pt-${inModal ? 3 : 5}`}>
        {currentRelatedContent?.length > 0 && (
          <div className='mb-4'>
            <ul className='policy-list px-0'>
              <RelatedContent relatedContent={currentRelatedContent} deleteItemFunction={deleteItemFunction} />
            </ul>
          </div>
        )}

        {!inModal && (
          <h3 className='title-h5-m-bold '>Would you like to associate other content with this {userContentType}?</h3>
        )}
        <p className={`main-light-text ${inModal ? 'paragraph-general' : 'mb-4 paragraph-p2'}`}>
          Search for political updates including lobbying material, legislation, debates and consultations
        </p>
        <input
          autoFocus={true}
          data-hj-allow
          ref={inputRef}
          type='text'
          className='w-100 form-control main-input input-search'
          placeholder={
            inModal
              ? 'Search the political landscape'
              : 'Search for briefing material, policy asks or political developments'
          }
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && e.target.value !== '') {
              searchFunction();
            }
          }}
        />
      </div>

      {relatedSearchResults !== null && (
        <div className='menu-items-influence pt-4'>
          <MenuItems {...props} updatePosition enabled={enabledSwiper} />
        </div>
      )}

      {loadingRelatedSuggestions && (
        <div className='pt-4 px-4 px-lg-5 related-content-loading-suggestions'>
          <SkeletonTheme baseColor='#f2f2f2'>
            <ul className='policy-list px-0 items-list'>
              {Array(3)
                .fill()
                .map((item, index) => (
                  <li className={`my-2`} key={index}>
                    <div className='row mx-0 p-3 align-items-center'>
                      <div className='content-title pr-lg-5 pr-2 ml-2'>
                        <div className='my-1'>
                          <Skeleton height={19} width={`100%`} />
                        </div>
                        <div className='my-1'>
                          <Skeleton height={19} width={`100%`} />
                        </div>
                        <div className='my-1'>
                          <Skeleton height={19} width={`80%`} />
                        </div>
                      </div>
                      <Skeleton className='d-none d-sm-block' height={85} width={`114px`} />
                    </div>
                  </li>
                ))}
            </ul>
          </SkeletonTheme>
        </div>
      )}
      {!loadingRelatedSuggestions && !!relatedSearchResults && !!relatedSearchResults[typeOfContent] && (
        <>
          <div className='px-4 px-lg-5 search-modal-related-results' onScroll={onScrollFunction}>
            {relatedSearchResults[typeOfContent].length === 0 && (
              <div className='h-100 d-flex justify-content-between align-items-center'>
                <p
                  className='text-center mx-auto main-light-text'
                  style={{
                    maxWidth: '400px',
                    paddingTop: '50px',
                  }}
                >
                  No {TransformFromPascalCase(typeOfContent).toLowerCase()}
                  {typeOfContent === 'ParliamentaryRecord' ? 's' : ''} found. Click on the links above to explore other
                  content types
                </p>
              </div>
            )}
            {relatedSearchResults[typeOfContent].length > 0 && (
              <>
                <p className={`paragraph-p2 main-light-text pt-3`}>
                  {inModal
                    ? `${customText ?? 'Click on an item below to insert it as a tracked link into your email'}`
                    : `Click on items below to associate them with your ${userContentType}`}
                </p>
                <ul className='policy-list px-0 mb-0'>
                  {relatedSearchResults[typeOfContent].map((item, index) => (
                    <RelatedContentItem
                      key={`searchItem-${index}`}
                      item={item}
                      selectResource={selectResource}
                      value={associatedContent}
                      searchValue={searchValue}
                      insertContentLink={insertContentLink}
                    />
                  ))}
                </ul>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

const RelatedContent = (props) => {
  const { relatedContent, deleteItemFunction } = props;
  return (
    <>
      {relatedContent?.map((item, index) => {
        const { title, contentUrl, dateTime, contentType } = item;
        //AE: Getting the type from the URL, it's because contentType returns the specific contentType of item that is not usefull to get the icons
        let textType = uppercaseFirstLetter(contentUrl.split('/')[1].replace('-', ' '));
        if (textType === 'Key updates' || textType === 'Policy asks' || textType === 'Consultations') {
          textType = textType.substring(0, textType.length - 1);
        }

        const iconClass =
          textType === 'Policy asks' || textType === 'News release' || textType === 'Research'
            ? 'briefing-material'
            : `${normalize(textType)}`;

        return (
          <div className='position-relative related-item-container' key={`item-${index}`}>
            {contentType?.toLowerCase() === 'tweet' ? (
              <TweetItem item={item} />
            ) : (
              <a
                className={`mb-3 related-item related-${iconClass}`}
                href={`${contentUrl}`}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <span className={`icon-${relatedItemsIconMapping[iconClass]}`}></span>
                <li>
                  <p className='related-title'>{title}</p>
                  {!!dateTime && (
                    <p className='influence-related-date related-date heading-date'>
                      {textType} <span className='dot' />
                      {formatGeneralDate(dateTime)}
                    </p>
                  )}
                </li>
              </a>
            )}

            {deleteItemFunction && (
              <button
                className='general-button delete-button indented-button'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteItemFunction({ item });
                }}
              >
                Delete
              </button>
            )}
          </div>
        );
      })}
    </>
  );
};

export { RelatedContent };
export default SearchRelatedContent;
