import { useContext } from 'react';
import getAppSite from 'utils/getAppSite';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import { store } from 'components/Store';
import transformToPascalCase from 'components/Settings/Utilities/TransformToPascalCase';

const sectionName = (location) => {
  const path = location ?? window.location.pathname;
  switch (true) {
    case path.includes('/general-election-2024/key-updates'):
      return 'ElectionKeyUpdates';
    case path.includes('/key-updates'):
      return 'KeyUpdates';
    case path.includes('/consultations'):
      return 'Consultations';
    case path.includes('/legislation'):
      return 'Legislation';
    case path.includes('/parliamentary-record'):
      return 'ParliamentaryRecord';
    case path.includes('/tweets'):
      return 'Tweets';
    case path.includes('/calendar'):
    case path.includes('/upcoming-events'):
      return 'UpcomingEvents';
    case path.includes('/stakeholders'):
    case path.includes('/stakeholder-mapping'):
    case path.includes('/policymakers'):
      return 'Stakeholders';
    case path.includes('/policy-asks'):
      return 'PolicyAsks';
    case path.includes('/briefing-material'):
      return 'BriefingMaterial';
    case path.includes('/news-release'):
      return 'NewsRelease';
    case path.includes('/research'):
      return 'Research';
    case path.includes('/library-material'):
      return 'LibraryMaterial';
    case path.includes('/lobbying-material'):
      return 'LobbyingMaterial';
    case path.includes('/have-your-voice-heard'):
      return 'Influence';
    case path.includes('/campaigns'):
      return 'Campaigns';
    case path.includes('/influence/directory'):
      return 'InfluenceContacts';
    case path.includes('/influence/my-groups'):
      return 'Groups';
    case path.includes('/general-election-2024/candidates'):
      return 'Candidates';
    case path.includes('/committee-publication'):
      return 'CommitteePublications';
    case path.includes('/parliament-live'):
      return 'ParliamentLive';
    default:
      return '';
  }
};

const createResourcesName = () => {
  let section = sectionName();
  switch (section) {
    case 'ParliamentaryRecord':
      return 'parliamentary contributions';
    case 'Consultation':
      return 'consultations';
    case 'Tweets':
      return 'Tweets';
    default:
      return TransformFromPascalCase(section)?.toLowerCase() ?? '';
  }
};

const createFiltersProperties = () => {
  let properties = {
    section: sectionName(),
  };
  if (window.location.pathname.includes('tweets')) {
    properties = {
      ...properties,
      typeOfContent: 'SocialMedia',
      section: 'SocialMedia',
    };
  }
  return properties;
};

const useCreateSectionTitle = () => {
  const globalState = useContext(store);
  const { state } = globalState;

  const { search, referenceState } = state;
  const { activeSearch } = search;
  const { activeReference } = referenceState;

  const createSectionTitle = () => {
    let section = sectionName();
    switch (section) {
      case 'Consultations':
        return activeSearch || activeReference ? 'Consultations' : 'All open consultations';
      case 'ParliamentaryRecord':
        return getAppSite() === 'usa' ? 'Congressional record' : 'Parliamentary record';
      case 'ParliamentLive':
        return 'Live updates from Parliament';
      default:
        return TransformFromPascalCase(section);
    }
  };
  return { createSectionTitle };
};

const regexSectionName = (location) => {
  switch (location) {
    case location.match(/\/key-updates\/\d+\//i)?.input:
      return 'Key updates';
    case location.match(/\/consultations\/\d+\//i)?.input:
      return 'Consultations';
    case location.match(/\/legislation\/\w+\/\d+\//i)?.input:
      return 'Legislation';
    case location.match(/\/parliamentary-record\/[\w-]+\/\d+\//i)?.input:
      return 'Parliamentary record';
    case location.match(/\/library-material\/\d+\//i)?.input:
      return 'Library material';
    case location.match(/\/briefing-material\/\d+\//i)?.input:
      return 'Briefing material';
    case location.match(/\/policy-asks\/\d+\//i)?.input:
      return 'Policy asks';
    case location.match(/\/research\/\d+\//i)?.input:
      return 'Research';
    case location.match(/\/news-release\/\d+\//i)?.input:
      return 'News release';
    case location.match(/\/stakeholders\/\d+\//i)?.input:
      return 'Stakeholders';
    case location.match(/\/directory\/\d+\//i)?.input:
      return 'InfluenceContacts';
    case location.match(/\/committee-publication\/\d+\//i)?.input:
      return 'Committee publications';
    case location.match(/\/parliament-live\/\d+\//i)?.input:
      return 'Parliament live';
    default:
      return null;
  }
};

const createSectionName = (contentPage) => {
  let section = contentPage ? transformToPascalCase(regexSectionName(window.location.pathname)) : sectionName();
  switch (section) {
    case 'BriefingMaterial':
    case 'PolicyAsks':
    case 'Research':
    case 'NewsRelease':
    case 'LobbyingMaterial':
    case 'Campaigns':
      return 'Influence';
    case 'Tweets':
      return 'SocialMedia';
    default:
      return section;
  }
};

export {
  sectionName,
  createResourcesName,
  createFiltersProperties,
  useCreateSectionTitle,
  regexSectionName,
  createSectionName,
};
