import React, { useEffect, useState, useContext } from 'react';
import InfluenceHeader from './ui/InfluenceHeader';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import axios from 'axios';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { store } from 'components/Store.js';
import Loading from 'components/Login/Loading';
import CreateMaterial from './ui/CreateMaterial';
import { Link } from 'react-router-dom';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import SkeletonAddContent from 'components/Dashboard/Influence/skeletons/SkeletonAddContent';
import { loadKeyWords } from 'components/Dashboard/utilities/loadKeyWords';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import getCurrentTeam from 'components/Settings/Team/getCurrentTeam.js';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import { useResetClientNavigation } from 'components/Dashboard/Navigation/ClientNavigation.js';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags';
import useCallTeamsContent from 'components/Dashboard/apiCalls/callTeamsContent';
import useInitialValidations from 'components/useInitialValidations';

const InfluenceAddContent = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { keywordsLists, readyKeywordLists, userProfile, team } = state;
  const [heightContainer, containerRef] = useHeightContainer();
  const [isLoading, setIsLoading] = useState(true);
  const [hasDraftItems, setHasDraftItems] = useState(false);
  const [teamsContent, setTeamsContent] = useState(true);
  const step = getUrlParam('step');

  const keywordsListsToUse = keywordsLists?.filter((item) => item?.id !== null);
  const [type, setType] = useState('Briefing material');
  const [client, setClient] = useState(keywordsListsToUse?.[0]);

  const { generalApiCall } = useGeneralApiCall();
  const { getAccessToken } = useGetAccessToken();
  const { newAgencyPlan } = useTopicOrClientWord();
  const { resetClientState } = useResetClientNavigation();
  const { callTeamsContent } = useCallTeamsContent();
  const { addMetaTags } = useAddMetaTags();

  const callCurrentUser = async () => {
    let method = 'get';
    let pathname = `/api/user/getcurrent`;
    let results = await generalApiCall({ method, pathname, needsAuthentication: true });
    return results;
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    resetClientState();
    const loadResults = async () => {
      try {
        let results = await callTeamsContent({ source });
        if (Object.keys(userProfile).length === 0) {
          let user = await callCurrentUser();
          if (!!user) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'userProfile', value: user });
          }
        }

        if (Object.keys(team).length === 0) {
          let team = await getCurrentTeam(getAccessToken, source);
          if (!!team) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: team });
          }
        }

        let topics = keywordsLists;
        if (!readyKeywordLists) {
          topics = await loadKeyWords(getAccessToken, source);
          setClient(topics?.[0]);
          dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: topics });
        }

        if (!!results) {
          let hasDrafts = false;
          results.forEach((item) => {
            if (item.publishedAt === null) {
              hasDrafts = true;
            }
          });
          setTeamsContent(results);
          setHasDraftItems(hasDrafts);
          let title = `Add content - Have your voice heard - PolicyMogul`;
          addMetaTags({ title });
          setIsLoading(false);
        }
      } catch (error) {}
    };
    loadResults();
    return () => {
      source.cancel('Team content canceled by the user');
    };
  }, []);

  const createTitle = () => {
    switch (step) {
      case '1-agency':
        return 'Select a client to add lobbying material on their behalf';
      case '2-agency':
        return `Associate a webpage with your lobbying material`;
      case '3':
        return `Give your ${type?.toLowerCase()} a headline`;
      default:
        return `Choose the content type you'd like to add ${newAgencyPlan ? `for ${team?.teamName}` : ''}`;
    }
  };

  const createSubtitle = () => {
    switch (step) {
      case '1-agency':
        return 'Lobbying material will be shared with parliamentarians and other organisations, depending on your visibility settings.';
      case '2-agency':
        return `Use your client's homepage unless they have a specific policy or campaign page`;
      case '3':
        return 'Summarise your lobbying material in one sentence';
      default:
        return 'Help parliamentarians and others understand the nature of your lobbying material';
    }
  };
  useInitialValidations();

  return (
    <>
      <InfluenceHeader />
      <div
        className='d-lg-flex justify-content-lg-center align-items-lg-center'
        ref={containerRef}
        style={{
          height: `${heightContainer}px`,
        }}
      >
        {isLoading && <SkeletonAddContent />}
        {!isLoading && (
          <div className='text-center message-content add-content-message-container px-4 main-add-content-page'>
            <div className='py-5 py-lg-0'>
              <h3 className='px-3 px-lg-0 title-h1-bold mt-5 mt-lg-0'>{createTitle()}</h3>
              <p
                className={`mb-5 main-light-text mx-auto font-weight-normal title-h5-m subtitle-add-content subtitle-${step}`}
              >
                {createSubtitle()}
              </p>
              <CreateMaterial
                type={type}
                setType={setType}
                client={client}
                setClient={setClient}
                keywordsLists={keywordsListsToUse}
                organisationWebsite={
                  teamsContent?.filter((item) => item?.clientKeywordListId === client?.id)?.[0]?.websiteLink
                }
                team={team}
              />
              {hasDraftItems && (
                <Link
                  to='/influence/lobbying-material'
                  className='text-center main-light-text mt-5 d-block unpublished-text'
                >
                  You have unpublished content. Would you prefer to edit that instead?
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default withAuthenticationRequired(InfluenceAddContent, {
  onRedirecting: () => <Loading />,
});
