import { useContext, useEffect, useRef, useState } from 'react';
import { store } from 'components/Store';
import { useParams } from 'react-router-dom';
import ScrollbarList from 'components/Common/ScrollbarList';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import SkeletonInfluenceList, {
  SkeletonInfluenceSingleItem,
} from 'components/Dashboard/Influence/skeletons/SkeletonInfluenceList';
import LegislationItem from 'components/Dashboard/Legislation/LegislationItem';
import SkeletonLegislation, { SkeletonLegislationItem } from 'components/Dashboard/Legislation/SkeletonLegislation';
import LibraryMaterialItem from 'components/Dashboard/LibraryMaterial/LibraryMaterialItem';
import ParliamentaryItem from 'components/Dashboard/ParliamentaryRecord/ParlamentaryItem';
import SkeletonParliamentary, {
  SkeletonParliamentaryItem,
} from 'components/Dashboard/ParliamentaryRecord/SkeletonParliamentary';
import WaitingAnimationScreen from 'components/Dashboard/StakeHolders/WaitingAnimationScreen';
import { createCalendarRenderElements } from 'components/Dashboard/UpcomingEvents/CalendarSearchRenderFunctions';
import SkeletonCalendarSearch from 'components/Dashboard/UpcomingEvents/SkeletonCalendarSearch';
import InfluenceItem from 'components/Dashboard/UserContent/ui/InfluenceListItem';
import EmptyMessage from 'components/Dashboard/components/EmptyMessage';
import ResearchPortalButton from 'components/Dashboard/components/ResearchPortalButton';
import SkeletonConsultation, {
  SkeletonConsultationItem,
} from 'components/Dashboard/ConsultationsSection/SkeletonConsultation';
import ConsultationItem from 'components/Dashboard/ConsultationsSection/consultationItem';
import { sectionName as rawSectionName, createSectionName } from 'components/Dashboard/helpers/controlActiveFilters';
import ItemNew from 'components/Dashboard/KeyUpdates/ItemNew';
import SkeletonKeyUpdates, { SkeletonItem } from 'components/Dashboard/KeyUpdates/SkeletonKeyUpdates';
import SkeletonItemTwitter from 'components/Dashboard/SocialMedia/SkeletonItemTwitter';
import SkeletonTwitter from 'components/Dashboard/SocialMedia/SkeletonTwitter';
import TweetItem from 'components/Dashboard/SocialMedia/TweetItem';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import CommitteePublicationItem from 'components/Dashboard/CommitteePublication/CommitteePublicationItem';
import ParliamentLiveItem from 'components/Dashboard/ParliamentLive/ParliamentLiveItem.js';

const GeneralList = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { sectionsView, contentResults, activeResults } = state;
  const { selectedOptionParliamentLiveView } = sectionsView ?? {};
  const { isLoading, createResults, currentlyProcessing, elements, section, scrollBarRef } = props;
  const sectionName = createSectionName();
  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);
  const [freshItemsHeight, setFreshItemsHeight] = useState(0);
  const [freshElementsBtnHidden, setFreshElementsBtnHidden] = useState(false);
  const freshItemsRef = useRef();

  useEffect(() => {
    if (freshItemsRef.current) {
      setFreshItemsHeight(freshItemsRef.current.getBoundingClientRect().height);
    }
  }, [elements.length, freshItemsRef.current]);

  const freshElements = elements.filter((item) => item.freshItem === true);
  const transitionDuration = freshElements.length >= 15 ? 1000 : 500;

  const showNewItems = () => {
    setFreshElementsBtnHidden(true);
    if (freshItemsRef.current) {
      freshItemsRef.current.classList.add('scrolled');
      setFreshItemsHeight(0);
      setTimeout(() => {
        let sectionWithoutScroll = { ...section, hits: elements.map((item) => ({ ...item, freshItem: false })) };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: {
            ...contentResults,
            [activeResults]: {
              [sectionName]: sectionWithoutScroll,
            },
          },
        });
        freshItemsRef.current.classList.remove('scrolled');
        setFreshElementsBtnHidden(false);
      }, transitionDuration);
    }
  };

  const createEmptyMessage = () => {
    switch (rawSectionName()) {
      case 'ParliamentaryRecord':
        return 'updates';
      case 'ParliamentLive':
        return 'parliamentary proceedings';
      default:
        return TransformFromPascalCase(rawSectionName())?.toLowerCase();
    }
  };

  return (
    <div className={`col-16 px-0`}>
      {isLoading && (
        <>
          {(() => {
            switch (sectionName) {
              case 'KeyUpdates':
                return <SkeletonKeyUpdates />;
              case 'Consultations':
                return <SkeletonConsultation />;
              case 'Legislation':
                return <SkeletonLegislation />;
              case 'ParliamentLive':
                return <SkeletonConsultation />;
              case 'ParliamentaryRecord':
                return <SkeletonParliamentary />;
              case 'UpcomingEvents':
                return <SkeletonCalendarSearch />;
              case 'Influence':
                return <SkeletonInfluenceList influencePage={false} />;
              case 'SocialMedia':
                return <SkeletonTwitter />;
              default:
                return <SkeletonKeyUpdates />;
            }
          })()}
        </>
      )}
      {!isLoading && (
        <>
          <button
            className={`fresh-items-button general-button action-button rounded-button alerts-button z-max ${freshElements.length > 0 && !freshElementsBtnHidden ? '' : 'fresh-items-button-hidden'}`}
            onClick={showNewItems}
          >
            <span className='icon-arrow-top mr-2'></span>New{' '}
            {selectedOptionParliamentLiveView === 'Relevant remarks' ? 'remarks' : 'events'} ({freshElements.length})
          </button>
          {currentlyProcessing.state === false && inboxSection ? (
            <WaitingAnimationScreen section={section} />
          ) : (
            <>
              {section?.loaded && (
                <>
                  {elements.length === 0 ? (
                    <EmptyMessage resourceName={createEmptyMessage()} />
                  ) : (
                    <>
                      <ScrollbarList ref={scrollBarRef} createResults={createResults}>
                        {sectionName === 'UpcomingEvents' ? (
                          <div className='py-2'>
                            {createCalendarRenderElements({ elements, feedItemFlags: section?.feedItemFlags })}
                          </div>
                        ) : (
                          <>
                            {sectionName === 'ParliamentLive' && (
                              <ul
                                ref={freshItemsRef}
                                className='policy-list px-0 items-list all-open-consultations fresh-items-list mb-0'
                                style={{
                                  marginTop: `-${freshItemsHeight}px`,
                                  transition: `margin-top ${transitionDuration}ms ease-out`,
                                }}
                              >
                                {elements
                                  ?.filter((item) => item?.freshItem)
                                  .map((item, index, array) => {
                                    return (
                                      <ParliamentLiveItem
                                        key={`item-${normalize(sectionName)}-${index}`}
                                        item={item}
                                        array={array}
                                        index={index}
                                        scrollBarRef={scrollBarRef}
                                        listView={true}
                                      />
                                    );
                                  })}
                              </ul>
                            )}

                            <ul className='policy-list px-0 items-list pb-2 all-open-consultations'>
                              {elements
                                ?.filter((item) => !item?.freshItem)
                                .map((item, index, array) => {
                                  const commonProperties = {
                                    item,
                                    array,
                                    index,
                                    scrollBarRef,
                                    listView: true,
                                    openInNewTab: sectionName === 'ElectionKeyUpdates',
                                  };
                                  switch (sectionName) {
                                    case 'KeyUpdates':
                                    case 'ElectionKeyUpdates':
                                      return (
                                        <ItemNew
                                          key={`item-${normalize(sectionName)}-${index}`}
                                          switchRelevantExtractOrSummary={true}
                                          {...commonProperties}
                                        />
                                      );
                                    case 'Consultations':
                                      return (
                                        <ConsultationItem
                                          {...commonProperties}
                                          key={`item-${normalize(sectionName)}-${index}`}
                                          includeTags={true}
                                          title={item?.departmentName}
                                          isAll={!section.totalHits}
                                        />
                                      );
                                    case 'Legislation':
                                      return (
                                        <LegislationItem
                                          key={`item-${normalize(sectionName)}-${index}`}
                                          {...commonProperties}
                                          legislationType={item?.contentType}
                                        />
                                      );
                                    case 'ParliamentLive':
                                      return (
                                        <ParliamentLiveItem
                                          key={`item-${normalize(sectionName)}-${index}`}
                                          {...commonProperties}
                                          legislationType={item?.contentType}
                                        />
                                      );
                                    case 'CommitteePublications':
                                      return (
                                        <CommitteePublicationItem
                                          key={`item-${normalize(sectionName)}-${index}`}
                                          {...commonProperties}
                                          numberOfContributions={section?.collapsedHits[index]?.totalHits}
                                        />
                                      );
                                    case 'ParliamentaryRecord':
                                      return (
                                        <ParliamentaryItem
                                          key={`item-${normalize(sectionName)}-${index}`}
                                          {...commonProperties}
                                          numberOfContributions={section?.collapsedHits[index]?.totalHits}
                                        />
                                      );
                                    case 'LibraryMaterial':
                                      return (
                                        <LibraryMaterialItem
                                          key={`item-${normalize(sectionName)}-${index}`}
                                          {...commonProperties}
                                          feedItemFlags={section?.feedItemFlags}
                                        />
                                      );
                                    case 'Influence':
                                      return (
                                        <InfluenceItem
                                          key={`item-${normalize(sectionName)}-${index}`}
                                          {...commonProperties}
                                          feedItemFlags={section?.feedItemFlags}
                                        />
                                      );
                                    case 'SocialMedia':
                                      return (
                                        <TweetItem
                                          key={`item-${normalize(sectionName)}-${index}`}
                                          {...commonProperties}
                                        />
                                      );
                                    default:
                                      return '';
                                  }
                                })}
                              {section?.thereIsMoreContent && sectionName !== 'ParliamentLive' && (
                                <>
                                  {(() => {
                                    switch (sectionName) {
                                      case 'KeyUpdates':
                                        return <SkeletonItem />;
                                      case 'Consultations':
                                        return <SkeletonConsultationItem />;
                                      case 'Legislation':
                                        return <SkeletonLegislationItem />;
                                      case 'ParliamentaryRecord':
                                      case 'CommitteePublication':
                                        return <SkeletonParliamentaryItem />;
                                      case 'Influence':
                                        return <SkeletonInfluenceSingleItem />;
                                      case 'SocialMedia':
                                        return <SkeletonItemTwitter />;
                                      default:
                                        return <SkeletonItem />;
                                    }
                                  })()}
                                </>
                              )}
                            </ul>
                          </>
                        )}
                        <ResearchPortalButton />
                      </ScrollbarList>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GeneralList;
