import React, { useContext, useState, useRef } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import { TwitterPicker } from 'react-color';
import { store } from 'components/Store';
import rgbHex from 'rgb-hex';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Portal } from 'react-portal';
import ModalComponent from 'components/Common/Modal';
import parliamentarianUsers from 'components/Dashboard/SidebarElements/parliamentarianUsers';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { useAuth0 } from '@auth0/auth0-react';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';

const ReportDesignButton = (props) => {
  const {
    designProps,
    componentText = 'report',
    leftPosition,
    isOpenDesign,
    setIsOpenDesign,
    feed,
    chartButton,
    customColours,
    property,
  } = props;
  const { designState, setDesignState } = designProps ?? {};
  const changeState = ({ property, newValue }) => {
    let newState = { ...designState };
    newState[property] = newValue;
    setDesignState(newState);
  };

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const isOpen = isOpenDesign ?? isOpenPopup;
  const setIsOpen = setIsOpenDesign ?? setIsOpenPopup;
  const actionsElement = useRef();
  const { width, top, left, height } = actionsElement?.current?.getBoundingClientRect() ?? {};

  return (
    <div className='position-relative' ref={actionsElement}>
      <button
        className={`general-button rounded-pill border px-4 mr-3 hovered-button report-design-button bg-main-white`}
        onClick={() => setIsOpen(!isOpen)}
      >
        Design
      </button>
      {isOpen && (
        <Portal>
          <>
            <div
              className='general-close-background adjustment-of-position'
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <div
              className='position-fixed rounded border bg-main-white report-design-options adjustment-of-position'
              style={{
                top: `${top + height + 5}px`,
                left: `${leftPosition ? left : left + width - 512}px`,
              }}
            >
              <div className='border-bottom flex-centered px-4 py-3'>
                <h3 className='mb-0 font-weight-bold title-h5'>Change the design of this {componentText}</h3>
              </div>
              <div className='px-4 py-3'>
                <ReportDesignPopup
                  designState={designState}
                  changeState={changeState}
                  componentText={componentText}
                  feed={feed}
                  chartButton={chartButton}
                  customColours={customColours}
                  property={property}
                />
              </div>
            </div>
          </>
        </Portal>
      )}
    </div>
  );
};

const ReportDesignPopup = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableReportsRemoveBranding, subscriptionStatus, code } = activePlan;
  const { designState, changeState, componentText = 'report', feed, chartButton, customColours, property } = props;

  const { headerColour, backgroundColour, includeBranding, barColour } = designState ?? {};

  const tickBox = useRef();

  const trialAccount = subscriptionStatus === 'InTrial' || subscriptionStatus === 'TrialEnded';
  const enableRemoveBranding = (property ? activePlan[property] : enableReportsRemoveBranding) && !trialAccount;
  const { isAuthenticated } = useAuth0();
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      <div className='d-lg-flex py-2 '>
        {!chartButton && (
          <div className='analytics-popup-basis pb-4 pb-lg-0'>
            <p className='font-weight-bold mb-2'>Header colour</p>
            <ColorPicker
              customColours={customColours}
              color={headerColour}
              setColor={(color) => changeState({ property: 'headerColour', newValue: color })}
            />
          </div>
        )}
        {feed && (
          <div className='analytics-popup-basis pb-4 pb-lg-0'>
            <p className='font-weight-bold mb-2'>Bar colour</p>
            <ColorPicker
              customColours={customColours}
              color={barColour}
              setColor={(color) => changeState({ property: 'barColour', newValue: color })}
            />
          </div>
        )}
        <div className={`analytics-popup-basis`}>
          <p className='font-weight-bold mb-2'>{feed ? 'Body' : 'Background'} colour</p>
          <ColorPicker
            customColours={customColours}
            color={backgroundColour}
            setColor={(color) => changeState({ property: 'backgroundColour', newValue: color })}
          />
        </div>
      </div>
      {!parliamentarianUsers(code) && (
        <div className='py-4'>
          <div className='pl-1 pt-2'>
            <div
              className='checkbox-item d-flex my-0'
              ref={tickBox}
              data-tooltip-content={
                isAuthenticated
                  ? !enableRemoveBranding
                    ? `Upgrade to ${trialAccount ? 'a paid' : 'an'} ${newAgencyPlan && trialAccount ? '' : 'Enterprise'} subscription to remove PolicyMogul branding`
                    : ''
                  : 'Create an account to remove PolicyMogul branding'
              }
              data-tooltip-event={!enableRemoveBranding ? 'click' : 'hover'}
              data-tooltip-id={'report-design-tooltip'}
            >
              <input
                id='pmBranding'
                type='checkbox'
                checked={includeBranding}
                onChange={() => {
                  createNotification(
                    'success',
                    `PolicyMogul branding ${includeBranding ? 'removed from' : 'included in'} this ${componentText}`,
                    'bottom-left'
                  );
                  changeState({ property: 'includeBranding', newValue: !includeBranding });
                }}
                disabled={!enableRemoveBranding}
              />
              <label htmlFor='pmBranding'>
                <p className='font-weight-bold mb-2'>
                  Include PolicyMogul branding {!enableRemoveBranding && <span className='icon-padlock ml-2' />}
                </p>
                <p className='light-50-text mb-0 paragraph-p2 font-weight-normal' style={{ lineHeight: '1.4' }}>
                  ‘Powered by PolicyMogul’ will {includeBranding ? '' : 'not '}appear at bottom of {componentText}
                </p>
              </label>
            </div>
          </div>
        </div>
      )}
      {/* <div className="rounded-bottom bg-light-blue-hovered px-4 border-top pb-3">
        <div className="pl-1 pt-1">
          <div className='checkbox-item d-flex mb-0'>
            <input 
              id='pm-defaults'
              type='checkbox' 
              checked={makeDefaultsSettings} 
              value={makeDefaultsSettings}
              onChange={()=>changeState({property: 'makeDefaultsSettings', newValue: !makeDefaultsSettings})} 
            />
            <label htmlFor="pm-defaults" style={{paddingTop: '1px'}}>
              <p className="mb-0">Make these my default settings for all reports</p>
            </label>
          </div>
        </div>
      </div> */}

      <ReactTooltip
        id={'report-design-tooltip'}
        effect={'solid'}
        type={'info'}
        className={'pricing-feature-tooltip feed-tooltip'}
        arrowColor={'transparent'}
        backgroundColor={'#00122B'}
        multiline={true}
      />
    </>
  );
};

const ColorPicker = (props) => {
  const { color, setColor, customColours } = props;

  const [isOpen, setIsOpen] = useState(false);
  const hideOptions = () => {
    setIsOpen(false);
  };
  const actionsElement = useRef();
  useOutsideElement(actionsElement, hideOptions);
  return (
    <div className='flex-centered'>
      <div className='position-relative mr-1' ref={actionsElement}>
        <div
          className='rounded-circle border pointer'
          onClick={() => setIsOpen(!isOpen)}
          style={{ width: '27px', height: '27px', backgroundColor: color }}
        />
        {isOpen && (
          <div className='report-color-picker position-absolute z-10'>
            <TwitterPicker
              colors={
                customColours ?? [
                  '#FBDBC5',
                  '#FFE9B5',
                  '#E2F1EB',
                  '#D2E9E0',
                  '#DFF1FB',
                  '#B1DFF6',
                  '#E4E9ED',
                  '#F7C2D5',
                  '#FBDDE5',
                  '#FFFFFF',
                ]
              }
              color={color}
              onChange={(c) => {
                setColor('#' + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a));
              }}
            />
          </div>
        )}
      </div>
      <p className='m-0 ml-2 pointer' onClick={() => setIsOpen(!isOpen)}>
        {formatColor(color).toUpperCase()}
      </p>
    </div>
  );
};

const hex_is_light = (color = '#FFFFFF') => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
};

const DesignPopupInsideMoreButton = (props) => {
  const { designProps, isOpen, setIsOpen } = props;
  const { setDesignState, designState } = designProps;
  const changeState = ({ property, newValue }) => {
    let newState = { ...designState };
    newState[property] = newValue;
    setDesignState(newState);
  };

  return (
    <ModalComponent
      setIsOpen={(value) => {
        setIsOpen(value);
      }}
      overlayClassName='react-modal-overlay report-extra-index-modal'
      isOpen={isOpen}
      maxWidth={'496'}
    >
      <div className='border-bottom flex-centered px-4 py-3'>
        <h3 className='mb-0 font-weight-bold title-h5'>Change the design of this report</h3>
      </div>
      <div className='px-4 py-3'>
        <ReportDesignPopup designState={designState} changeState={changeState} isFromPopup={true} />
      </div>
    </ModalComponent>
  );
};

const formatColor = (color) => {
  return (color?.length > 7 ? color?.slice(0, -2) : color).toUpperCase();
};

export { hex_is_light, DesignPopupInsideMoreButton, formatColor, ReportDesignPopup };
export default ReportDesignButton;
