import { useLocation, useParams } from 'react-router-dom';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useCallCollectContent from 'components/Dashboard/hooks/useCallCollectContent';
import useGetMatchingKeywordsListsForContent from 'components/Dashboard/hooks/useMatchingKeywordsListsForContent';
import useCreateResults from 'components/Dashboard/ListControl/useCreateResults';
import { useCallContentRelated } from 'components/Dashboard/utilities/callContentRelated';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createNavigationLinksOnSection,
  getIdPositionOnSection,
} from 'components/Dashboard/helpers/navigationFunctions';
import useCallRelatedStakeholders from 'components/Dashboard/KeyUpdates/useCallStakeholders';
import { createSectionName, regexSectionName } from 'components/Dashboard/helpers/controlActiveFilters';
import { store } from 'components/Store';
import { useContext, useState } from 'react';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import callHightlightContentAPI from 'components/Dashboard/utilities/callHightlightContentAPI';
import updateUnreadItems from 'components/Dashboard/helpers/updateUnreadItems';
import useGetParliamentaryContributions from 'components/Dashboard/ParliamentaryRecord/useGetParliamentaryContributions';
import ChangeKeywordsExclusions from 'components/Dashboard/utilities/changeKeywordExclusions';
import useGetHighlightingKeywords from 'components/Dashboard/hooks/useGetHighlighingKeywords';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags';
import getAppSite from 'utils/getAppSite.js';

const useLoadContentResults = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contentResults, activeResults, search, referenceState } = state;

  const { activeSearch } = search;
  const { activeReference } = referenceState;
  const { setNavigationLinks, idPosition, setIsLoading } = props;

  const params = useParams();
  const { isAuthenticated } = useAuth0();
  const { id, type } = params;

  const sectionName = createSectionName(true);
  const section = contentResults[activeResults][sectionName];
  const getIdPosition = getIdPositionOnSection(sectionName, id);

  const [isLoadingRelatedContent, setIsLoadingRelatedContent] = useState(true);

  const createNavigationLinks = createNavigationLinksOnSection(sectionName);
  const { callRelatedStakeholders } = useCallRelatedStakeholders({});
  const { queryHighlight, andKeywords } = useGetHighlightingKeywords();
  const { callCollectContent } = useCallCollectContent();
  const { load_more_results } = useCreateResults({ propsSectionName: sectionName });
  const { getMatchingKeywordsListsAndFeedback } = useGetMatchingKeywordsListsForContent();
  const { callContentRelated } = useCallContentRelated();
  const { generalApiCall } = useGeneralApiCall();
  const { getTotalContributions, getContactIdInformation } = useGetParliamentaryContributions({});
  const { addMetaTags } = useAddMetaTags();
  const location = useLocation();

  const numberOfStakeHoldersToShow = parseInt(process.env.REACT_APP_RELATED_STAKEHOLDERS_TO_SHOW);

  const detectIfLoadMoreResults = () => {
    if (getIdPosition() !== null && getIdPosition() === section?.hits?.length - 1) {
      return true;
    }
    return false;
  };

  const updateResultsOnState = (newData) => {
    const data = {
      ...contentResults,
      [activeResults]: {
        [sectionName]: newData,
      },
    };
    dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: data });
  };

  const apiToCall = () => {
    switch (type) {
      case 'scottish-official-report':
        return 'country-specific-parliamentary-record/scotland';
      case 'welsh-official-report':
        return 'country-specific-parliamentary-record/wales';
      case 'welsh-written-q-and-a':
        return 'country-specific-question-and-answer/wales';
      case 'scottish-written-q-and-a':
        return 'country-specific-question-and-answer/scotland';
      case 'northern-irish-written-q-and-a':
        return 'country-specific-question-and-answer/northernireland';
      case 'written-q-and-a':
        return 'question-and-answer';
      case 'edm':
        return 'edm';
      case 'senedd-committee-transcript':
        return 'senedd-committee-transcript';
      case 'crec':
        return 'crec';
      default:
        return 'parliamentaryrecord';
    }
  };

  const createPathname = ({ path }) => {
    const pathname = path;
    let characters = pathname.split('');
    if (characters?.[characters?.length - 1] === 's') {
      characters.splice(characters?.length - 1, 1);
    }
    const commonPathname = characters?.join('')?.replace(' ', '')?.toLowerCase();
    switch (sectionName) {
      case 'CommitteePublications':
        return `/api/committee-publication/${id}`;

      case 'LibraryMaterial':
        return `/api/commons-library-article/${id}`;

      case 'Legislation':
        return `/api/${type}legislation/${id}`;
      case 'ParliamentaryRecord':
        return `/api/${apiToCall()}/${id}`;
      default:
        return `/api/${commonPathname}/${id}`;
    }
  };

  const propertyToHighlight = () => {
    switch (sectionName) {
      case 'KeyUpdates':
        return 'subject';
      case 'Legislation':
        return type === 'primary' ? 'billTitle' : 'title';
      default:
        return 'title';
    }
  };

  const bodyPropertyToHighlight = ({ data }) => {
    switch (sectionName) {
      case 'Legislation':
        return type === 'primary' ? 'summary' : 'explanatoryNote';
      case 'ParliamentaryRecord':
        return data?.content ? 'content' : data?.htmlContent ? 'htmlContent' : 'body';
      default:
        return 'body';
    }
  };

  const loadNew = async (source) => {
    try {
      setIsLoadingRelatedContent(true);
      setIsLoading(true);
      //LOAD THE LIST IF IT IS THE LAST ITEM
      let listResults;
      if (detectIfLoadMoreResults()) {
        listResults = await load_more_results(source);
        setNavigationLinks(createNavigationLinks(getIdPosition()));
      }

      //NEEDS WORK ON PATHNAMES
      const newResult = await generalApiCall({
        pathname: createPathname({ path: regexSectionName(location?.pathname) }),
        method: 'get',
        needsAuthentication: isAuthenticated,
        requestSource: source,
        notShowErrorMessage: true,
      });

      const { objectId, objectType, contentUrl } = newResult;
      let newData = { ...newResult };
      const contentHightlighted = await highlightContent({ content: newData, source });
      const { feedback, keywordsFromLists, keywordsListsIds } = await getMatchingKeywordsListsAndFeedback({ objectId });
      newData = { ...contentHightlighted, feedback, keywordsFromLists, keywordsListsIds };

      const contentObject = !!listResults ? listResults[activeResults][sectionName] : section;
      let newSectionObject = contentObject;
      //REMOVE THE ITEM FROM THE LIST AND MARK AS READ
      const removeUnreadItem = updateUnreadItems({ idPosition, contentObject, getIdPosition });
      if (removeUnreadItem) {
        newSectionObject = removeUnreadItem;
      }
      const initialData = {
        ...newSectionObject,
        new: newData,
        currentId: parseInt(id),
      };

      updateResultsOnState(initialData);
      updateMetatags({ content: newData });
      setIsLoading(false);

      //CALLS NECESSARY TO DO AFTER THE CONTENT IS SHOWN IN ORDER TO SPEED UP THE PAGE
      const { totalContributions, contactIdInformation } = await getTotalContributionsAndContactIdInformation({
        content: newData,
        source,
      });
      let contentRelated = await callContentRelated({ objectId, objectType, source });
      const { relatedContentWithStakeholders, contactIdsConditional, contactsIdsCalled, answersIds, contactIdsToUse } =
        await getRelatedStakeholders({ content: newData, contentRelated, source });
      const relatedData = {
        ...initialData,
        totalContributions,
        contactIdInformation: contactIdInformation?.hits?.[0],
        contentRelated: relatedContentWithStakeholders,
        relatedStakeholders: contactIdsConditional
          ? (contactIdsToUse ?? answersIds).filter((id) => !contactsIdsCalled.includes(id))
          : null,
        relatedStakeholdersRendered: contactIdsConditional
          ? relatedContentWithStakeholders?.hits.StakeHolders?.length
          : 0,
      };
      updateResultsOnState(relatedData);
      setIsLoadingRelatedContent(false);
      await callCollectContent({ objectId, objectType, contentUrl });
    } catch (error) {}
  };

  const highlightContent = async ({ content, source }) => {
    const { objectId, granuleId, objectType, questionHasAnswer, title, questionText, answerText } = content ?? {};
    const createHighlightProperties = (objectType) => {
      let propertiesHighlight = [
        objectId ?? granuleId,
        objectType ?? 'CREC',
        queryHighlight,
        ChangeKeywordsExclusions(andKeywords),
        source,
      ];
      return propertiesHighlight;
    };

    let newData = { ...content };
    let highlightCondition = activeSearch || activeReference || getUrlParam('search');
    if (highlightCondition) {
      if (type?.includes('q-and-a')) {
        const propertiesHighlightQuestion = createHighlightProperties('WrittenQuestion');
        const propertiesHighlightAnswer = createHighlightProperties('WrittenAnswer');
        let contenthighlightedQuestion = await callHightlightContentAPI(propertiesHighlightQuestion);
        let contenthighlightedAnswer;
        if (questionHasAnswer) {
          contenthighlightedAnswer = await callHightlightContentAPI(propertiesHighlightAnswer);
        }
        newData = {
          ...newData,
          questionForHighlighted: contenthighlightedQuestion?.highlightedTitle ?? title,
          questionText: contenthighlightedQuestion?.highlightedHtmlBody ?? questionText,
          answerText: contenthighlightedAnswer?.highlightedHtmlBody ?? answerText,
        };
      } else {
        let propertiesHighlight = createHighlightProperties(objectType);
        let contentHightlighted = await callHightlightContentAPI(propertiesHighlight);
        if (!!contentHightlighted) {
          const { highlightedTitle, highlightedHtmlBody } = contentHightlighted;
          newData = {
            ...newData,
            [`${propertyToHighlight()}Highlighted`]: !!highlightedTitle
              ? highlightedTitle
              : newData?.[propertyToHighlight()],
            [bodyPropertyToHighlight({ data: newData })]: highlightedHtmlBody ?? newData?.[propertyToHighlight()],
          };
        }
      }
    }

    return newData;
  };

  const updateMetatags = ({ content }) => {
    const { imageId, contentUrl, descriptionMeta } = content;
    const title = `${content[propertyToHighlight()]} - ${regexSectionName(location?.pathname)} - PolicyMogul`;
    const hash = window.location.hash;
    const description = descriptionMeta;
    const image = imageId;
    addMetaTags({ title, hash, contentUrl, description, image });
  };

  const getRelatedStakeholders = async ({ content, contentRelated, source }) => {
    const { contactIds, answerByContactId, questionByContactId, members } = content ?? {};
    const contactIdsToUse = getAppSite() === 'usa' ? members?.map((item) => item.contactId) : contactIds;
    const contactIdsConditional = !!contactIds?.length > 0 || !!answerByContactId || !!questionByContactId;
    let answersIds = [];
    let contactsIdsCalled = [];
    let relatedContentWithStakeholders = { ...contentRelated };
    if (contactIdsConditional) {
      let stakeholderFilters = [];

      for (let i = 0; i < numberOfStakeHoldersToShow; i++) {
        let contactId = contactIdsToUse?.[i];
        if (contactId !== undefined) {
          contactsIdsCalled.push(contactId);
          stakeholderFilters.push({ field: 'id', value: `${contactId}`, operator: 'str_eq' });
        }
      }

      //Questions and answers
      if (!!answerByContactId) {
        answersIds.push(answerByContactId);
        contactsIdsCalled.push(answerByContactId);
        stakeholderFilters.push({ field: 'id', value: `${answerByContactId}`, operator: 'str_eq' });
      }
      if (!!questionByContactId) {
        answersIds.push(questionByContactId);
        contactsIdsCalled.push(questionByContactId);
        stakeholderFilters.push({ field: 'id', value: `${questionByContactId}`, operator: 'str_eq' });
      }

      let stakeHolderResults = await callRelatedStakeholders({ stakeholderFilters, source });
      let stakeholdersRendered = stakeHolderResults?.data?.hits;
      let contactsIdsFiltered = contactIds?.filter((id) => !contactsIdsCalled.includes(id));

      while (
        contactsIdsFiltered?.length > numberOfStakeHoldersToShow &&
        stakeholdersRendered?.length < numberOfStakeHoldersToShow
      ) {
        let pageSize = numberOfStakeHoldersToShow - stakeholdersRendered?.length;
        let initialStakeholdersContactsIds = contactsIdsFiltered.slice(0, pageSize);
        let newStakeholdersFilters = initialStakeholdersContactsIds?.map((contactId) => {
          return { field: 'id', value: `${contactId}`, operator: 'str_eq' };
        });
        contactsIdsCalled = [...contactsIdsCalled, ...initialStakeholdersContactsIds];
        let newStakeholders = await callRelatedStakeholders({
          stakeholderFilters: newStakeholdersFilters,
          pageSize,
          source,
        });
        stakeholdersRendered = [...stakeholdersRendered, ...newStakeholders?.data?.hits];
      }

      relatedContentWithStakeholders = {
        ...relatedContentWithStakeholders,
        hits: { ...relatedContentWithStakeholders['hits'], StakeHolders: stakeholdersRendered },
      };
    }
    return { contactIdsConditional, relatedContentWithStakeholders, contactsIdsCalled, answersIds, contactIdsToUse };
  };

  const getTotalContributionsAndContactIdInformation = async ({ source, content }) => {
    const { canonicalUrl } = content ?? {};
    let totalContributions;
    let contactIdInformation;
    if (
      (type === 'hansard-content' || type === 'scottish-official-report' || 'welsh-official-report') &&
      (getUrlParam('topic-id') || getUrlParam('search') || getUrlParam('or'))
    ) {
      totalContributions = await getTotalContributions({ contentUrl: canonicalUrl, type, source });
      if (getUrlParam('contact-id')) {
        contactIdInformation = await getContactIdInformation(getUrlParam('contact-id'), source);
      }
    }
    return { totalContributions, contactIdInformation };
  };

  return { loadNew, isLoadingRelatedContent };
};

export default useLoadContentResults;
